var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button, Icon, Input, LoadingBubbles, Typography } from 'designSystem';
import { useNavigate } from 'react-router-dom';
import { useScreenSizing } from 'hooks';
import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { passwordVerification, } from 'utils/passwordVerification';
export var ChangePassword = function () {
    var navigate = useNavigate();
    var _a = __read(useState(''), 2), oldPassword = _a[0], setOldPassword = _a[1];
    var _b = __read(useState(''), 2), newPassword = _b[0], setNewPassword = _b[1];
    var _c = __read(useState(''), 2), confirmPassword = _c[0], setConfirmPassword = _c[1];
    var _d = __read(useState({
        minimum: false,
        uppercase: false,
        lowercase: false,
        number: false,
        misMatch: false,
    }), 2), passwordRequirements = _d[0], setPasswordRequirements = _d[1];
    var _e = __read(useState(false), 2), validationRequirementsMet = _e[0], setValidationRequirementsMet = _e[1];
    var _f = __read(useState(false), 2), showOldPassword = _f[0], setShowOldPassword = _f[1];
    var _g = __read(useState(false), 2), showNewPassword = _g[0], setShowNewPassword = _g[1];
    var _h = __read(useState(false), 2), showConfirmPassword = _h[0], setShowConfirmPassword = _h[1];
    var _j = __read(useState(false), 2), showBanner = _j[0], setShowBanner = _j[1];
    var _k = __read(useState(false), 2), isSaving = _k[0], setIsSaving = _k[1];
    var _l = __read(useState(null), 2), error = _l[0], setError = _l[1];
    var isMobile = useScreenSizing().isMobile;
    var theme = useTheme();
    var checkPasswordRequirements = function () {
        var verification = passwordVerification(newPassword, oldPassword);
        if (newPassword !== null) {
            setPasswordRequirements(verification.passwordRequirements);
            setValidationRequirementsMet(verification.allTestsPassing);
        }
    };
    useEffect(function () {
        checkPasswordRequirements();
    }, [newPassword]);
    var updatePassword = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIsSaving(true);
            setError(null);
            try {
                setShowBanner(true);
            }
            catch (e) {
                setError(e);
            }
            finally {
                setIsSaving(false);
            }
            return [2];
        });
    }); };
    var passwordButtonDisabled = !oldPassword ||
        !newPassword ||
        !confirmPassword ||
        !validationRequirementsMet ||
        newPassword !== confirmPassword ||
        isSaving;
    return (React.createElement(OuterContainer, null,
        React.createElement(InnerContainer, null,
            showBanner && (React.createElement(BannerContainer, null,
                React.createElement(CheckContainer, null,
                    React.createElement(CheckIcon, { name: "tick", size: 16, color: theme.colors.border.success })),
                React.createElement(Typography, { variant: "headline", color: "alt3" }, "Password Successfully Updated"),
                !isMobile && (React.createElement(CloseContainer, null,
                    React.createElement(CloseButton, { onClick: function () { return setShowBanner(false); } },
                        React.createElement(Icon, { name: "close", color: "white", size: 24 })))))),
            React.createElement(ContentContainer, { showBanner: showBanner },
                React.createElement(Typography, { variant: "titleLarge" }, "Change Password"),
                !isMobile && (React.createElement(Typography, { color: "alt", mt: 16 }, "Change your password.")),
                React.createElement(ChangePasswordContainer, null,
                    React.createElement(PasswordInputContainer, null,
                        React.createElement(Input, { rightIcon: showOldPassword ? 'hide-password' : 'show-password', onRightIconClicked: function () { return setShowOldPassword(!showOldPassword); }, type: showOldPassword ? 'text' : 'password', label: "Old Password", placeholder: "Old Password", onChange: function (e) { return setOldPassword(e.target.value); }, value: oldPassword }),
                        React.createElement(InputSpacer, null),
                        React.createElement(Input, { rightIcon: showNewPassword ? 'hide-password' : 'show-password', onRightIconClicked: function () { return setShowNewPassword(!showNewPassword); }, type: showNewPassword ? 'text' : 'password', label: "New Password", placeholder: "New Password", onChange: function (e) { return setNewPassword(e.target.value); }, value: newPassword }),
                        React.createElement(InputSpacer, null),
                        React.createElement(Input, { rightIcon: showConfirmPassword ? 'hide-password' : 'show-password', onRightIconClicked: function () {
                                return setShowConfirmPassword(!showConfirmPassword);
                            }, type: showConfirmPassword ? 'text' : 'password', label: "Confirm New Password", placeholder: "Confirm New Password", onChange: function (e) { return setConfirmPassword(e.target.value); }, value: confirmPassword }),
                        React.createElement(ButtonContainer, null,
                            React.createElement(Button, { size: "xlarge", fullWidth: isMobile, onClick: function () { return navigate('/home'); }, disabled: !!passwordButtonDisabled }, isSaving ? React.createElement(LoadingBubbles, null) : 'Save New Password')),
                        error && (React.createElement(Typography, { color: "link", mt: 24 }, "Sorry, something went wrong!"))),
                    React.createElement(PasswordRequirementsContainer, null,
                        React.createElement("div", null,
                            React.createElement(Typography, { variant: "headlineSmall", color: "alt" }, "Password Requirements"),
                            React.createElement("div", null,
                                React.createElement(RequirementItem, null,
                                    passwordRequirements.minimum ? (React.createElement(Check, null,
                                        React.createElement(CheckIcon, { name: "tick", size: 10 }))) : (React.createElement(Bullet, null)),
                                    React.createElement(Typography, { variant: "bodySmall" }, "8 character minimum")),
                                React.createElement(RequirementItem, null,
                                    passwordRequirements.lowercase ? (React.createElement(Check, null,
                                        React.createElement(CheckIcon, { name: "tick", size: 10 }))) : (React.createElement(Bullet, null)),
                                    React.createElement(Typography, { variant: "bodySmall" }, "At least 1 lowercase letter")),
                                React.createElement(RequirementItem, null,
                                    passwordRequirements.uppercase ? (React.createElement(Check, null,
                                        React.createElement(CheckIcon, { name: "tick", size: 10 }))) : (React.createElement(Bullet, null)),
                                    React.createElement(Typography, { variant: "bodySmall" }, "At least 1 uppercase letter")),
                                React.createElement(RequirementItem, null,
                                    passwordRequirements.number ? (React.createElement(Check, null,
                                        React.createElement(CheckIcon, { name: "tick", size: 10 }))) : (React.createElement(Bullet, null)),
                                    React.createElement(Typography, { variant: "bodySmall" }, "At least 1 number")),
                                React.createElement(RequirementItem, null,
                                    oldPassword &&
                                        newPassword &&
                                        passwordRequirements.misMatch ? (React.createElement(Check, null,
                                        React.createElement(CheckIcon, { name: "tick", size: 10 }))) : (React.createElement(Bullet, null)),
                                    React.createElement(Typography, { variant: "bodySmall" }, "Cannot reuse old password"))))))))));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  margin-top: 16px;\n  padding: 32px;\n  @media screen and (max-width: ", "px) {\n    padding: 0;\n  }\n"], ["\n  ", ";\n  margin-top: 16px;\n  padding: 32px;\n  @media screen and (max-width: ", "px) {\n    padding: 0;\n  }\n"])), function (props) { return props.theme.card.alt; }, function (props) { return props.theme.breakpoints.md; });
var InnerContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  border: 1px solid ", ";\n\n  padding: 0 0 48px 0;\n  @media screen and (max-width: ", "px) {\n    border: none;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  border: 1px solid ", ";\n\n  padding: 0 0 48px 0;\n  @media screen and (max-width: ", "px) {\n    border: none;\n  }\n"])), function (props) { return props.theme.colors.border.base; }, function (props) { return props.theme.breakpoints.md; });
var ContentContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 32px 48px;\n  flex-direction: column;\n  display: flex;\n  margin-top: ", ";\n  padding: 0 32px;\n"], ["\n  padding: 32px 48px;\n  flex-direction: column;\n  display: flex;\n  margin-top: ", ";\n  padding: 0 32px;\n"])), function (props) { return (props.showBanner ? '16px' : '48px'); });
var ChangePasswordContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  margin-top: 32px;\n  @media screen and (max-width: ", "px) {\n    & > div:first-of-type {\n      order: 1;\n    }\n    display: flex;\n    flex-direction: column;\n    border: none;\n    padding: 0;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  margin-top: 32px;\n  @media screen and (max-width: ", "px) {\n    & > div:first-of-type {\n      order: 1;\n    }\n    display: flex;\n    flex-direction: column;\n    border: none;\n    padding: 0;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var PasswordInputContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  margin-right: 32px;\n  @media screen and (max-width: ", "px) {\n    margin-right: 0;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  margin-right: 32px;\n  @media screen and (max-width: ", "px) {\n    margin-right: 0;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var PasswordRequirementsContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  margin-left: 32px;\n  @media screen and (max-width: ", "px) {\n    margin-left: 0;\n    margin-bottom: 24px;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  margin-left: 32px;\n  @media screen and (max-width: ", "px) {\n    margin-left: 0;\n    margin-bottom: 24px;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var RequirementItem = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-top: 24px;\n  @media screen and (max-width: ", "px) {\n    margin-top: 0;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-top: 24px;\n  @media screen and (max-width: ", "px) {\n    margin-top: 0;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var Check = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: ", ";\n\n  margin-right: 8px;\n  width: 20px;\n  height: 20px;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: ", ";\n\n  margin-right: 8px;\n  width: 20px;\n  height: 20px;\n"])), function (props) { return props.theme.colors.border.success2; });
var CheckIcon = styled(Icon)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  height: 15px;\n  color: ", ";\n"], ["\n  height: 15px;\n  color: ", ";\n"])), function (props) { return props.theme.colors.utility.success; });
var Bullet = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 6px;\n  height: 6px;\n  border: 3px solid black;\n\n  margin-right: 12px;\n"], ["\n  width: 6px;\n  height: 6px;\n  border: 3px solid black;\n\n  margin-right: 12px;\n"])));
var InputSpacer = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin-bottom: 24px;\n"], ["\n  margin-bottom: 24px;\n"])));
var ButtonContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin-top: 48px;\n"], ["\n  margin-top: 48px;\n"])));
var BannerContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 20px 48px;\n  background-color: ", ";\n  @media screen and (max-width: ", "px) {\n    justify-content: center;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 20px 48px;\n  background-color: ", ";\n  @media screen and (max-width: ", "px) {\n    justify-content: center;\n  }\n"])), function (props) { return props.theme.colors.utility.success; }, function (props) { return props.theme.breakpoints.md; });
var CheckContainer = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 24px;\n  height: 24px;\n  background-color: ", ";\n\n  margin-right: 16px;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 24px;\n  height: 24px;\n  background-color: ", ";\n\n  margin-right: 16px;\n"])), function (props) { return props.theme.colors.border.success2; });
var CloseContainer = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: flex-end;\n  cursor: pointer;\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: flex-end;\n  cursor: pointer;\n"])));
var CloseButton = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 30px;\n  height: 30px;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 30px;\n  height: 30px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
