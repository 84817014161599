var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'designSystem/components/Button';
import { Input } from 'designSystem/components/Input';
import { useModal } from 'context/ModalContext';
import { Modal } from '../../Modal';
import { useUser } from 'context';
import { Typography } from 'designSystem';
import { useScreenSizing } from 'hooks';
var AddCCModal = function (_a) {
    var refetch = _a.refetch;
    var closeModal = useModal().closeModal;
    var account = useUser().account;
    var _b = __read(useState({
        name: '',
        cardNumber: '',
        expires: '',
        cvc: '',
        zip: '',
    }), 2), paymentMethodInfo = _b[0], setPaymentMethodInfo = _b[1];
    var _c = __read(useState('0000-0000-0000-0000'), 2), cardNumberMask = _c[0], setCardNumberMask = _c[1];
    var _d = __read(useState(false), 2), formComplete = _d[0], setFormComplete = _d[1];
    var isMobile = useScreenSizing().isMobile;
    var addCreditCard = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2];
    }); }); };
    useEffect(function () {
        var _a;
        if (((_a = paymentMethodInfo === null || paymentMethodInfo === void 0 ? void 0 : paymentMethodInfo.cardNumber) === null || _a === void 0 ? void 0 : _a[0]) === '3') {
            setCardNumberMask('0000-000000-00000');
        }
        else {
            setCardNumberMask('0000-0000-0000-0000');
        }
    }, [paymentMethodInfo.cardNumber]);
    useEffect(function () {
        if (Object.values(paymentMethodInfo).every(function (item) { return item; })) {
            setFormComplete(true);
        }
        else {
            setFormComplete(false);
        }
    }, [paymentMethodInfo]);
    return (React.createElement(Modal, null,
        React.createElement(ContentContainer, null,
            React.createElement(Typography, { variant: "display", align: "center" }, "Add a Credit or Debit Card"),
            React.createElement(Input, { label: "Name", placeholder: "Payment Method Name", value: paymentMethodInfo.name, onChange: function (e) {
                    return setPaymentMethodInfo(__assign(__assign({}, paymentMethodInfo), { name: e.target.value }));
                } }),
            React.createElement(Input, { masked: true, maskChar: "_", mask: cardNumberMask, label: "Card Number", placeholder: "0000 0000 0000 0000", value: paymentMethodInfo.cardNumber, onChange: function (e) {
                    return setPaymentMethodInfo(__assign(__assign({}, paymentMethodInfo), { cardNumber: e.target.value.replace(/-/g, '') }));
                } }),
            React.createElement(CCTwoItemContainer, null,
                React.createElement(Input, { masked: true, mask: "00/00", label: "Expires", placeholder: "MM/YY", value: paymentMethodInfo.expires, onChange: function (e) {
                        return setPaymentMethodInfo(__assign(__assign({}, paymentMethodInfo), { expires: e.target.value.replace('/', '') }));
                    } }),
                React.createElement(Input, { label: "CVC", placeholder: "CVC Number", value: paymentMethodInfo.cvc, onChange: function (e) {
                        var cleanedCvc = e.target.value.replace(/[^\d]/g, '');
                        var validCvc = '';
                        if (cleanedCvc.length > 2 && cleanedCvc.length <= 4) {
                            validCvc = cleanedCvc;
                        }
                        else {
                            validCvc = cleanedCvc.substring(0, 4);
                        }
                        setPaymentMethodInfo(__assign(__assign({}, paymentMethodInfo), { cvc: validCvc }));
                    }, footerLabel: isMobile
                        ? undefined
                        : 'This is the 3-4 digit code in the front or back of your card.' })),
            React.createElement(ZipContainer, null,
                React.createElement(Input, { label: "Zip Code", placeholder: "Billing Zip Code", value: paymentMethodInfo.zip, onChange: function (e) {
                        return setPaymentMethodInfo(__assign(__assign({}, paymentMethodInfo), { zip: e.target.value }));
                    } })),
            React.createElement(ButtonContainer, null,
                React.createElement(Button, { size: "xlarge", variant: "tertiary", onClick: function () { return closeModal(); } }, "Cancel"),
                React.createElement(Button, { size: "xlarge", disabled: !formComplete ||
                        !(paymentMethodInfo.cvc.length === 3 ||
                            paymentMethodInfo.cvc.length === 4), variant: "primary", onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4, addCreditCard()];
                            case 1: return [2, _a.sent()];
                        }
                    }); }); } }, "Add Card")))));
};
export default AddCCModal;
var ContentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  & > span {\n    margin-bottom: 48px;\n    @media only screen and (max-width: ", "px) {\n      margin-bottom: 32px;\n    }\n  }\n  & > div {\n    margin-bottom: 24px;\n  }\n  & > div:last-of-type {\n    margin-bottom: 0;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  & > span {\n    margin-bottom: 48px;\n    @media only screen and (max-width: ", "px) {\n      margin-bottom: 32px;\n    }\n  }\n  & > div {\n    margin-bottom: 24px;\n  }\n  & > div:last-of-type {\n    margin-bottom: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var CCTwoItemContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: flex-start;\n  gap: 12px;\n  margin-bottom: 10px;\n  @media only screen and (max-width: ", "px) {\n    & > div {\n      width: unset;\n      max-width: calc(50% - 6px);\n      & > div {\n        display: block;\n        flex: unset;\n        & > input {\n          width: 100%;\n        }\n      }\n    }\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: flex-start;\n  gap: 12px;\n  margin-bottom: 10px;\n  @media only screen and (max-width: ", "px) {\n    & > div {\n      width: unset;\n      max-width: calc(50% - 6px);\n      & > div {\n        display: block;\n        flex: unset;\n        & > input {\n          width: 100%;\n        }\n      }\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ZipContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 50%;\n\n  @media only screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"], ["\n  width: 50%;\n\n  @media only screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ButtonContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    flex: 1;\n    margin-top: 8px;\n    justify-content: flex-start;\n    & > button {\n      width: 100%;\n    }\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    flex: 1;\n    margin-top: 8px;\n    justify-content: flex-start;\n    & > button {\n      width: 100%;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
