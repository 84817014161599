var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import View from './View';
var RewardsHistoryTableContainer = function (_a) {
    var _b, _c, _d, _e;
    var selectedTransactionType = _a.selectedTransactionType, selectedRewardTypes = _a.selectedRewardTypes, dateRange = _a.dateRange, selectedInjectors = _a.selectedInjectors, selectedFacility = _a.selectedFacility, onSelectItem = _a.onSelectItem;
    var _f = __read(useState([]), 2), activityFilter = _f[0], setActivityFilter = _f[1];
    var _g = __read(useState(0), 2), page = _g[0], setPage = _g[1];
    useEffect(function () {
        setActivityFilter(selectedTransactionType.map(function (type) { return type.value; }));
    }, [selectedTransactionType]);
    useEffect(function () {
        setPage(0);
    }, [
        selectedTransactionType,
        dateRange,
        selectedFacility,
        selectedInjectors,
        selectedRewardTypes,
    ]);
    var mockResponse = {
        data: {
            getTransactionHistoryActivity: {
                pageCount: 0,
                recordCount: 1,
                reportId: "report1",
                pageNumber: 1,
                activity: [
                    {
                        consumerFirstName: "John",
                        consumerLastName: "Doe",
                        activityType: "Purchase",
                        occurredOn: "2022-01-01",
                        phoneNumber: "1234567890",
                        creditsEarned: 10,
                        facilityName: "Facility 1",
                        rewardAmount: 5,
                        rewardEligibleAt: "2022-01-02",
                        rewardExpiration: "2022-12-31",
                        rewardPromotion: {
                            accountId: "account1",
                            amount: 5,
                            endDate: "2022-12-31",
                            terms: "Terms 1",
                            campaignId: "campaign1",
                            promoId: "promo1",
                            couponColor: "Red",
                            startDate: "2022-01-01"
                        },
                        rewardsGiven: 5,
                        redeemedForQuantity: 1,
                        redeemedForPoints: 10,
                        orderId: "order1",
                        staffFirstName: "Staff",
                        staffLastName: "Member",
                        email: "staff@facility.com",
                        checkInId: "checkin1",
                        facilityId: "facility1",
                        accountId: "account1",
                        injector: {
                            name: "Injector 1",
                            uniqueId: "uniq-1",
                            externalId: "ext-1"
                        }
                    },
                ]
            }
        }
    };
    return (React.createElement(View, { onSelectItem: onSelectItem, transactionHistory: (_c = (_b = mockResponse === null || mockResponse === void 0 ? void 0 : mockResponse.data) === null || _b === void 0 ? void 0 : _b.getTransactionHistoryActivity) === null || _c === void 0 ? void 0 : _c.activity, isLoading: false, error: undefined, page: page, setPage: setPage, recordCount: (_e = (_d = mockResponse === null || mockResponse === void 0 ? void 0 : mockResponse.data) === null || _d === void 0 ? void 0 : _d.getTransactionHistoryActivity) === null || _e === void 0 ? void 0 : _e.recordCount }));
};
export default RewardsHistoryTableContainer;
