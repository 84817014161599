var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button } from 'designSystem/components/Button';
import { Input } from 'designSystem/components/Input';
import { RadioButton } from 'designSystem';
import { useModal } from 'context/ModalContext';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal } from '../../Modal';
import { useUser } from 'context';
import { useScreenSizing } from 'hooks';
var AddBankAccountModal = function (_a) {
    var refetch = _a.refetch;
    var closeModal = useModal().closeModal;
    var isMobile = useScreenSizing().isMobile;
    var _b = __read(useState({
        name: '',
        accountOwner: '',
        routingNumber: '',
        accountNumber: '',
        relationship: '',
        accountType: '',
    }), 2), paymentMethodInfo = _b[0], setPaymentMethodInfo = _b[1];
    var _c = __read(useState(false), 2), formComplete = _c[0], setFormComplete = _c[1];
    var account = useUser().account;
    useEffect(function () {
        if (Object.values(paymentMethodInfo).every(function (item) { return item; })) {
            setFormComplete(true);
        }
        else {
            setFormComplete(false);
        }
    }, [paymentMethodInfo]);
    var addBankAccount = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2];
    }); }); };
    return (React.createElement(Modal, null,
        React.createElement(ContentContainer, null,
            React.createElement(HeaderText, null, "Add a Bank Account"),
            React.createElement(CCTwoItemContainer, null,
                React.createElement(Input, { label: "Name", placeholder: "Payment Method Name", value: paymentMethodInfo.name, onChange: function (e) {
                        return setPaymentMethodInfo(__assign(__assign({}, paymentMethodInfo), { name: e.target.value }));
                    } }),
                React.createElement(Input, { label: "Account Owner", placeholder: "Name on Account", value: paymentMethodInfo.accountOwner, onChange: function (e) {
                        return setPaymentMethodInfo(__assign(__assign({}, paymentMethodInfo), { accountOwner: e.target.value }));
                    } })),
            React.createElement(Spacer, null),
            React.createElement(CCTwoItemContainer, null,
                React.createElement(Input, { label: "Routing Number", placeholder: "Routing Number", value: paymentMethodInfo.routingNumber, onChange: function (e) {
                        return setPaymentMethodInfo(__assign(__assign({}, paymentMethodInfo), { routingNumber: e.target.value }));
                    }, footerLabel: "This is a 9-digit number identifying your financial institution." }),
                React.createElement(Input, { label: "Account Number", placeholder: "Account Number", value: paymentMethodInfo.accountNumber, onChange: function (e) {
                        return setPaymentMethodInfo(__assign(__assign({}, paymentMethodInfo), { accountNumber: e.target.value }));
                    }, footerLabel: "This is usually 8-12 digits long, sometimes longer." })),
            React.createElement(Spacer, null),
            React.createElement(CCTwoItemContainer, null,
                React.createElement(RadioContainer, null,
                    React.createElement(RadioHeaderText, null, "Relationship"),
                    React.createElement(RadioButtonContainer, null,
                        React.createElement(RadioButton, { onChange: function () {
                                return setPaymentMethodInfo(__assign(__assign({}, paymentMethodInfo), { relationship: 'BUSINESS' }));
                            }, checked: paymentMethodInfo.relationship === 'BUSINESS', title: "Business" }),
                        React.createElement(RadioButton, { onChange: function () {
                                return setPaymentMethodInfo(__assign(__assign({}, paymentMethodInfo), { relationship: 'PERSONAL' }));
                            }, checked: paymentMethodInfo.relationship === 'PERSONAL', title: "Personal" }))),
                React.createElement(RadioContainer, null,
                    React.createElement(RadioHeaderText, null, "Account Type"),
                    React.createElement(RadioButtonContainer, null,
                        React.createElement(RadioButton, { onChange: function () {
                                return setPaymentMethodInfo(__assign(__assign({}, paymentMethodInfo), { accountType: 'CHECKING' }));
                            }, checked: paymentMethodInfo.accountType === 'CHECKING', title: "Checking" }),
                        React.createElement(RadioButton, { onChange: function () {
                                return setPaymentMethodInfo(__assign(__assign({}, paymentMethodInfo), { accountType: 'SAVINGS' }));
                            }, checked: paymentMethodInfo.accountType === 'SAVINGS', title: "Savings" })))),
            React.createElement(ButtonContainer, null,
                React.createElement(Button, { size: "xlarge", variant: isMobile ? 'tertiary' : 'outlined', onClick: function () { return closeModal(); } }, "Cancel"),
                React.createElement(Button, { size: "xlarge", disabled: !formComplete, variant: "primary", onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4, addBankAccount()];
                            case 1: return [2, _a.sent()];
                        }
                    }); }); } }, "Add Bank Account")))));
};
export default AddBankAccountModal;
var ContentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"], ["\n  width: 100%;\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"])));
var HeaderText = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 2.25rem;\n  margin: 16px;\n  margin-bottom: 48px;\n  text-align: center;\n"], ["\n  font-size: 2.25rem;\n  margin: 16px;\n  margin-bottom: 48px;\n  text-align: center;\n"])));
var Spacer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 24px;\n"], ["\n  margin-top: 24px;\n"])));
var CCTwoItemContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  gap: 24px;\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  gap: 24px;\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var RadioContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"])));
var RadioButtonContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  margin-top: 20px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  margin-top: 20px;\n"])));
var RadioHeaderText = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 1rem;\n  color: ", ";\n"], ["\n  font-size: 1rem;\n  color: ", ";\n"])), function (props) { return props.theme.colors.text.alt; });
var ButtonContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-top: 64px;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    flex: 1;\n    margin-top: 32px;\n    & > button {\n      width: 100%;\n    }\n  }\n"], ["\n  margin-top: 64px;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 12px;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column-reverse;\n    flex: 1;\n    margin-top: 32px;\n    & > button {\n      width: 100%;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
