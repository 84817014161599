var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useMemo } from 'react';
import CurrentEvoluxLevel from 'components/CurrentEvoluxLevel';
import styled, { css } from 'styled-components';
import { OfferCard } from './components/OfferCard';
import { PlaceholderLoading, Typography } from 'designSystem';
import { addMobileStyles } from 'utils/addMobileStyles';
import NoOffers from 'assets/searching.svg';
import { stackedCardStyles } from '../../../../components/StackedMobileCard';
import { useScreenSizing } from '../../../../hooks';
import { KickerCard } from './components/KickerCard';
import { AnnualCampaignCard } from './components/AnnualCampaignCard';
import { ProspectCard } from './components/ProspectCard';
export var OffersView = function (_a) {
    var promotions = _a.promotions, currentLevel = _a.currentLevel, vialsPurchased = _a.vialsPurchased, loading = _a.loading;
    var isMobile = useScreenSizing().isMobile;
    var isCustomAccount = false;
    var sortedPromos = useMemo(function () { var _a; return (_a = promotions === null || promotions === void 0 ? void 0 : promotions.sort(function (a, b) { return a.meta.sortOrder - b.meta.sortOrder; })) !== null && _a !== void 0 ? _a : []; }, [promotions]);
    var renderPromos = function () {
        if (sortedPromos === null || sortedPromos === void 0 ? void 0 : sortedPromos.length) {
            return (React.createElement(CardsContainer, null, sortedPromos === null || sortedPromos === void 0 ? void 0 : sortedPromos.map(function (p, i) {
                var _a;
                if ((_a = p === null || p === void 0 ? void 0 : p.tags) === null || _a === void 0 ? void 0 : _a.includes('ANNUAL')) {
                    return (React.createElement(AnnualCampaignCard, { key: p === null || p === void 0 ? void 0 : p.campaignId, index: i, meta: p.meta, vialsPurchased: vialsPurchased }));
                }
                else if (p.promoId.includes('KICKER')) {
                    return React.createElement(KickerCard, { index: i, meta: p.meta, key: i });
                }
                else if (p.promoId.includes('PROSPECT')) {
                    return (React.createElement(ProspectCard, { index: i, meta: p.meta, promoId: p.promoId, standardDiscount: p.standardDiscount, key: i }));
                }
                return (React.createElement(OfferCard, { index: i, meta: p.meta, standardDiscount: p.standardDiscount, vialsFromThreshold: p.vialsFromThreshold, key: i }));
            })));
        }
        return (React.createElement(EmptyContainer, null,
            React.createElement(NoOffersImage, { src: NoOffers, alt: "no-offers" }),
            React.createElement(Typography, { variant: isMobile ? 'title' : 'titleLarge', align: isMobile ? 'center' : 'left' }, "No Offers Currently Available")));
    };
    var renderContent = function () {
        if (loading) {
            return (React.createElement(LoadingContainer, null,
                React.createElement(PlaceholderLoading, null)));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(RowContainer, null,
                React.createElement(ItemContainer, { flex: 2 },
                    React.createElement(CurrentEvoluxLevel, { level: currentLevel, context: "OFFERS" }))),
            renderPromos()));
    };
    return React.createElement(OuterContainer, null, renderContent());
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  & > div:first-of-type {\n    flex: none;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  & > div:first-of-type {\n    flex: none;\n  }\n"])));
var LoadingContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1 !important;\n  justify-content: center;\n  align-items: center;\n  ", "\n"], ["\n  display: flex;\n  flex: 1 !important;\n  justify-content: center;\n  align-items: center;\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.card.default;
});
var CardsContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: grid;\n  gap: 32px;\n  grid-template-columns: 1fr 1fr;\n  ", ";\n"], ["\n  display: grid;\n  gap: 32px;\n  grid-template-columns: 1fr 1fr;\n  ", ";\n"])), addMobileStyles(css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    grid-template-columns: 1fr;\n    gap: 0;\n    z-index: 1;\n  "], ["\n    grid-template-columns: 1fr;\n    gap: 0;\n    z-index: 1;\n  "])))));
var RowContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var ItemContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex: ", ";\n"], ["\n  display: flex;\n  flex: ", ";\n"])), function (props) { return props.flex; });
var EmptyContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin: 0 16px;\n  background: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  gap: 40px;\n  flex: 1;\n  ", ";\n  ", ";\n  ", "\n"], ["\n  margin: 0 16px;\n  background: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  gap: 40px;\n  flex: 1;\n  ", ";\n  ", ";\n  ", "\n"])), function (props) { return props.theme.colors.bg.body; }, function (props) { return props.theme.borders.base; }, function (props) { return props.theme.elevation.base.elevation1; }, addMobileStyles(css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    flex-direction: column;\n    margin: 0;\n    z-index: 2;\n    ", ";\n  "], ["\n    flex-direction: column;\n    margin: 0;\n    z-index: 2;\n    ", ";\n  "])), stackedCardStyles)));
var NoOffersImage = styled.img(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 300px;\n"], ["\n  width: 100%;\n  max-width: 300px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
