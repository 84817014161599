var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import styled, { useTheme } from 'styled-components';
import { Button } from 'designSystem/components/Button/Button';
import Separator from 'designSystem/components/Separator/Separator';
import { currencyFormatter } from 'utils/currencyFormatter';
import { pluralize } from 'utils/pluralize';
import jeuveauIcon from 'assets/Jeuveau-vial.png';
import { levelName } from 'utils/levelName';
import { PlaceholderLoading } from 'designSystem/components/PlaceholderLoading';
import { useModal } from 'context/ModalContext';
import ModalTypeEnum from 'types/enums/ModalTypeEnum';
import { LoadingBubbles } from 'designSystem/components/LoadingBubbles';
import { useScreenSizing } from 'hooks';
import { EvoLogo } from '../EvoLogo';
import { Typography } from 'designSystem';
import { OrderQuantityType } from 'types/enums';
import { ReceiptLineItem } from './components/ReceiptLineItem';
export var OrderSummaryView = function (_a) {
    var _b;
    var availableFreeVials = _a.availableFreeVials, autoContinue = _a.autoContinue, boostEnabled = _a.boostEnabled, calculateTotalSaved = _a.calculateTotalSaved, calculatePricing = _a.calculatePricing, calculatedPricing = _a.calculatedPricing, currentOrderType = _a.currentOrderType, prefillQuantity = _a.prefillQuantity, currentQuarterLoyalty = _a.currentQuarterLoyalty, getEvoluxSummary = _a.getEvoluxSummary, nextStep = _a.nextStep, onRestart = _a.onRestart, orderData = _a.orderData, pricingModel = _a.pricingModel, primaryButtonDisabled = _a.primaryButtonDisabled, primaryButtonText = _a.primaryButtonText, quoteObj = _a.quoteObj, setCompletedOrderData = _a.setCompletedOrderData, setOrderData = _a.setOrderData, anyLoading = _a.anyLoading, pageLoading = _a.pageLoading, error = _a.error, onCreate = _a.onCreate, discounts = _a.discounts, renderPreapplyMessage = _a.renderPreapplyMessage;
    var theme = useTheme();
    var navigate = useNavigate();
    var location = useLocation();
    var _c = useModal(), openModal = _c.openModal, closeModal = _c.closeModal;
    var isMobile = useScreenSizing().isMobile;
    var isBulk = currentOrderType === OrderQuantityType.BULK;
    var isALaCarte = currentOrderType === OrderQuantityType.A_LA_CARTE;
    var quantity = +(orderData === null || orderData === void 0 ? void 0 : orderData.quantity) +
        ((orderData === null || orderData === void 0 ? void 0 : orderData.applyFreeVials) ? +(orderData === null || orderData === void 0 ? void 0 : orderData.applyFreeVials) : 0) || 0;
    var hasPaidVials = !!((_b = orderData === null || orderData === void 0 ? void 0 : orderData.quantity) !== null && _b !== void 0 ? _b : 0);
    var isQuantitySelect = location.search === '?step=quantitySelection';
    var isRewardVialSelection = location.search === '?step=rewardVialSelection';
    var isAddressSelect = location.search === '?step=addressSelection';
    var isDeliveryDateSelect = location.search === '?step=deliveryDateSelection';
    var isReviewScreen = location.search === '?step=reviewOrderScreen';
    var isPaymentSelect = location.search === '?step=paymentMethodSelection';
    var isOrderConfirmation = location.search === '?step=orderConfirmation';
    var _d = useMemo(function () {
        if (!(discounts === null || discounts === void 0 ? void 0 : discounts.length)) {
            return {};
        }
        var customPricing = [];
        var custom = [];
        var bulk = [];
        var generic = [];
        discounts.forEach(function (d) {
            var type = d.type;
            if (type === 'CUSTOM_PRICING') {
                customPricing.push(d);
            }
            else if (type === 'CUSTOM') {
                custom.push(d);
            }
            else if (type === 'BULK_ORDER') {
                bulk.push(d);
            }
            else if (type === 'GENERIC') {
                generic.push(d);
            }
        });
        return {
            customPricingDiscounts: customPricing,
            customDiscounts: custom,
            bulkDiscounts: bulk,
            genericDiscounts: generic,
        };
    }, [discounts]), customPricingDiscounts = _d.customPricingDiscounts, customDiscounts = _d.customDiscounts, bulkDiscounts = _d.bulkDiscounts, genericDiscounts = _d.genericDiscounts;
    useEffect(function () {
        var _a;
        if (location.search === '?step=reviewOrderScreen' ||
            location.search === '?step=orderConfirmation' ||
            location.search === '?step=paymentMethodSelection') {
            calculatePricing((_a = orderData === null || orderData === void 0 ? void 0 : orderData.promotions) !== null && _a !== void 0 ? _a : []);
        }
    }, [location]);
    useEffect(function () {
        var shouldAutoContinue = autoContinue &&
            (quoteObj === null || quoteObj === void 0 ? void 0 : quoteObj.pricing) &&
            (orderData === null || orderData === void 0 ? void 0 : orderData.quantity) &&
            (orderData === null || orderData === void 0 ? void 0 : orderData.shippingMethod.serviceId) &&
            (location === null || location === void 0 ? void 0 : location.search) === '?step=quantitySelection';
        if (shouldAutoContinue) {
            onContinue(autoContinue, prefillQuantity);
        }
    }, [autoContinue, quoteObj, prefillQuantity, orderData, location]);
    var onContinue = function (autoContinue, quantity) { return __awaiter(void 0, void 0, void 0, function () {
        var upgradeData, isALaCarte, rate, ppu, loyaltyLevel_1, vialsNeededForUpgrade_1, completeOrderData, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    upgradeData = liveUpgradeWithin20Percent();
                    isALaCarte = orderData.orderType === 'A_LA_CARTE';
                    rate = (upgradeData !== null && upgradeData !== void 0 ? upgradeData : {}).rate;
                    ppu = rate !== null && rate !== void 0 ? rate : orderData === null || orderData === void 0 ? void 0 : orderData.pricePerUnit;
                    if (isQuantitySelect && !!upgradeData && isALaCarte) {
                        loyaltyLevel_1 = upgradeData.loyaltyLevel, vialsNeededForUpgrade_1 = upgradeData.vialsNeededForUpgrade;
                        openModal(ModalTypeEnum.AUTOMATIC_LEVEL_UPGRADE, {
                            pricingModel: pricingModel,
                            loyaltyLevel: loyaltyLevel_1,
                            vialsNeededForUpgrade: vialsNeededForUpgrade_1,
                            onAgree: function () {
                                setOrderData(__assign(__assign({}, orderData), { isLevelUpgradeOrRetain: true, quantity: orderData.quantity + vialsNeededForUpgrade_1, liveUpgradeRate: loyaltyLevel_1, pricePerUnit: ppu }));
                                navigate({ search: nextStep });
                            },
                            onRefuse: function () { return navigate({ search: nextStep }); },
                            getEvoluxSummary: getEvoluxSummary,
                            boostEnabled: boostEnabled,
                        });
                        return [2, null];
                    }
                    if (!(location.search === '?step=reviewOrderScreen')) return [3, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4, onCreate()];
                case 2:
                    completeOrderData = _a.sent();
                    setCompletedOrderData(completeOrderData);
                    navigate({ search: nextStep });
                    setOrderData({});
                    return [2, null];
                case 3:
                    e_1 = _a.sent();
                    return [2, openModal(ModalTypeEnum.PAYMENT_AUTH_ERROR)];
                case 4:
                    if (autoContinue && quantity) {
                        setOrderData(__assign(__assign({}, orderData), { quantity: quantity }));
                    }
                    return [2, navigate({ search: nextStep })];
            }
        });
    }); };
    var getRetainedCheckValues = function () {
        var _a;
        var previousAccountLoyaltyLevel = currentQuarterLoyalty.startOfQuarterPricedWith.level;
        var previousQuarterLevel = currentQuarterLoyalty.levels.find(function (l) { return l.name === previousAccountLoyaltyLevel; });
        var previousQuarterVialThreshold = (_a = previousQuarterLevel === null || previousQuarterLevel === void 0 ? void 0 : previousQuarterLevel.vialThreshold) !== null && _a !== void 0 ? _a : 0;
        var vialsToRetainPreviousLoyaltyLevel = previousQuarterVialThreshold - currentQuarterLoyalty.vialsPurchased;
        var diff = vialsToRetainPreviousLoyaltyLevel - +(orderData === null || orderData === void 0 ? void 0 : orderData.quantity);
        return {
            diff: diff,
            vialsToRetainPreviousLoyaltyLevel: vialsToRetainPreviousLoyaltyLevel,
            previousAccountLoyaltyLevel: previousAccountLoyaltyLevel,
        };
    };
    var liveUpgradeWithin20Percent = function () {
        var obj = quoteObj.pricing.rates
            .filter(function (r) { return r.quantity >= +(orderData === null || orderData === void 0 ? void 0 : orderData.quantity); })
            .find(function (r) {
            var diff = Math.abs(r.quantity - +(orderData === null || orderData === void 0 ? void 0 : orderData.quantity));
            var maxDiff = r.quantity * 0.2;
            return diff <= maxDiff && diff > 0;
        });
        if (obj) {
            return {
                vialsNeededForUpgrade: obj.quantity - orderData.quantity,
                loyaltyLevel: obj.loyaltyLevel,
                rate: obj.rate,
            };
        }
        var _a = getRetainedCheckValues(), diff = _a.diff, vialsToRetainPreviousLoyaltyLevel = _a.vialsToRetainPreviousLoyaltyLevel, previousAccountLoyaltyLevel = _a.previousAccountLoyaltyLevel;
        var maxDiff = vialsToRetainPreviousLoyaltyLevel * 0.2;
        if (diff <= maxDiff && diff > 0) {
            return {
                vialsNeededForUpgrade: vialsToRetainPreviousLoyaltyLevel - orderData.quantity,
                loyaltyLevel: previousAccountLoyaltyLevel,
                rate: quoteObj.pricing.rates[0].rate,
            };
        }
        return null;
    };
    var renderOrderSummary = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        var bulkDiscount = (isBulk && ((_b = (_a = orderData === null || orderData === void 0 ? void 0 : orderData.selectedBulkRate) === null || _a === void 0 ? void 0 : _a.discount) === null || _b === void 0 ? void 0 : _b.discountAmount)) || 0;
        if ((isBulk || isALaCarte) && !!+quantity) {
            return (React.createElement("div", { style: {
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                } },
                pageLoading ||
                    (error && (React.createElement(LoadingContainer, null,
                        React.createElement(PlaceholderLoading, { size: "small" })))),
                React.createElement(ReceiptLineItem, { label: "Your Price", value: currencyFormatter(orderData.pricePerUnit, 2), hasExtraSpacing: true }),
                React.createElement(ReceiptLineItem, { label: "List Price", value: currencyFormatter(orderData.msrp, 2), labelSubtext: "(price per 100 unit vial)", hasExtraSpacing: true }),
                React.createElement(Separator, { color: theme.colors.bg.base }),
                React.createElement(ReceiptLineItem, { label: "".concat(quantity, " Total Vial").concat(pluralize(quantity)), value: currencyFormatter(quantity * orderData.msrp, 2), labelSubtext: "(List Price)" }),
                React.createElement(BulletItemContainer, null,
                    (orderData === null || orderData === void 0 ? void 0 : orderData.applyFreeVials) ? (React.createElement(React.Fragment, null,
                        React.createElement(ReceiptLineItem, { variant: "discount", label: "\u2022   ".concat(orderData === null || orderData === void 0 ? void 0 : orderData.applyFreeVials, " Reward Vial").concat(pluralize(orderData === null || orderData === void 0 ? void 0 : orderData.applyFreeVials)), value: currencyFormatter(orderData.applyFreeVials * orderData.msrp, 2), hasExtraSpacing: true }),
                        React.createElement(ReceiptLineItem, { label: "\u2022   ".concat(quantity - (orderData === null || orderData === void 0 ? void 0 : orderData.applyFreeVials), " Vial").concat(pluralize(quantity - (orderData === null || orderData === void 0 ? void 0 : orderData.applyFreeVials))), value: currencyFormatter((quantity - (orderData === null || orderData === void 0 ? void 0 : orderData.applyFreeVials)) * orderData.msrp, 2), hasExtraSpacing: true }))) : null,
                    calculatedPricing && (React.createElement(React.Fragment, null,
                        hasPaidVials &&
                            (customPricingDiscounts === null || customPricingDiscounts === void 0 ? void 0 : customPricingDiscounts.map(function (price) { return (React.createElement(React.Fragment, null,
                                React.createElement(ReceiptLineItem, { label: "".concat((pricingModel === null || pricingModel === void 0 ? void 0 : pricingModel.includes('_LOYALTY'))
                                        ? orderData.liveUpgradeRate
                                            ? levelName(orderData.liveUpgradeRate)
                                            : levelName(orderData.currentLevel)
                                        : 'Your', " ").concat(orderData.liveUpgradeRate &&
                                        orderData.liveUpgradeRate > orderData.currentLevel
                                        ? 'Upgrade'
                                        : 'Discount', " "), labelSubtext: "(".concat(currencyFormatter(price.percentage * orderData.msrp, 2), "/vial)"), variant: "discount", value: currencyFormatter(Math.abs(price.total), 2), hasExtraSpacing: true }))); })), customDiscounts === null || customDiscounts === void 0 ? void 0 :
                        customDiscounts.map(function (price) { return (React.createElement(ReceiptLineItem, { label: price.description, value: currencyFormatter(Math.abs(price.total), 2), variant: "discount", hasExtraSpacing: true })); }), bulkDiscounts === null || bulkDiscounts === void 0 ? void 0 :
                        bulkDiscounts.map(function (price) { return (React.createElement(ReceiptLineItem, { label: price.description || 'Bulk Discount', value: currencyFormatter(Math.abs(price.total), 2), variant: "discount", hasExtraSpacing: true })); }), genericDiscounts === null || genericDiscounts === void 0 ? void 0 :
                        genericDiscounts.map(function (price) { return (React.createElement(React.Fragment, null,
                            React.createElement(ReceiptLineItem, { variant: "discount", hasExtraSpacing: true, label: price.description, value: currencyFormatter(Math.abs(price.total), 2) }))); }),
                        React.createElement(ReceiptLineItem, { label: "Subtotal", hasExtraSpacing: true, value: currencyFormatter(orderData.applyFreeVials
                                ? ((_c = calculatedPricing === null || calculatedPricing === void 0 ? void 0 : calculatedPricing.inputDisplay) === null || _c === void 0 ? void 0 : _c.subtotal) -
                                    (orderData.applyFreeVials * orderData.msrp -
                                        orderData.applyFreeVials * orderData.pricePerUnit)
                                : (_d = calculatedPricing === null || calculatedPricing === void 0 ? void 0 : calculatedPricing.inputDisplay) === null || _d === void 0 ? void 0 : _d.subtotal, 2) }), (_e = calculatedPricing === null || calculatedPricing === void 0 ? void 0 : calculatedPricing.inputDisplay) === null || _e === void 0 ? void 0 :
                        _e.discounts.filter(function (d) { return d.type === 'PAYMENT_METHOD'; }).map(function (price) { return (React.createElement(ReceiptLineItem, { hasExtraSpacing: true, label: "ACH Discount (".concat(price.percentage, "%)"), value: currencyFormatter(Math.abs(price.total), 2), variant: "discount" })); }),
                        (location.search === '?step=paymentMethodSelection' ||
                            location.search === '?step=reviewOrderScreen') && (React.createElement(ReceiptLineItem, { label: "Estimated Tax", hasExtraSpacing: true, value: currencyFormatter((_f = calculatedPricing === null || calculatedPricing === void 0 ? void 0 : calculatedPricing.inputDisplay) === null || _f === void 0 ? void 0 : _f.taxEstimated, 2) })),
                        (location.search === '?step=paymentMethodSelection' ||
                            location.search === '?step=reviewOrderScreen') && (React.createElement(ReceiptLineItem, { label: "Shipping", hasExtraSpacing: true, value: ((_h = (_g = calculatedPricing === null || calculatedPricing === void 0 ? void 0 : calculatedPricing.inputDisplay) === null || _g === void 0 ? void 0 : _g.shipping) === null || _h === void 0 ? void 0 : _h.fee)
                                ? currencyFormatter((_k = (_j = calculatedPricing === null || calculatedPricing === void 0 ? void 0 : calculatedPricing.inputDisplay) === null || _j === void 0 ? void 0 : _j.shipping) === null || _k === void 0 ? void 0 : _k.fee, 2)
                                : 'Free' })),
                        React.createElement(Separator, { color: theme.colors.bg.base }),
                        React.createElement(ReceiptLineItem, { label: "Total", value: currencyFormatter((_l = calculatedPricing === null || calculatedPricing === void 0 ? void 0 : calculatedPricing.inputDisplay) === null || _l === void 0 ? void 0 : _l.totalEstimated, 2) }),
                        React.createElement(ReceiptLineItem, { label: "Total Saved", variant: "discount", hasExtraSpacing: true, value: currencyFormatter(calculateTotalSaved(calculatedPricing === null || calculatedPricing === void 0 ? void 0 : calculatedPricing.inputDisplay), 2) })))),
                !calculatedPricing && (orderData === null || orderData === void 0 ? void 0 : orderData.liveUpgradeRate) && (React.createElement(ReceiptLineItem, { label: "".concat((pricingModel === null || pricingModel === void 0 ? void 0 : pricingModel.includes('_LOYALTY'))
                        ? orderData.liveUpgradeRate
                            ? levelName(orderData.liveUpgradeRate)
                            : levelName(orderData.currentLevel)
                        : 'Your', " ").concat(orderData.liveUpgradeRate &&
                        orderData.liveUpgradeRate > orderData.currentLevel
                        ? 'Upgrade'
                        : 'Discount', " "), labelSubtext: "(".concat(currencyFormatter(orderData.msrp - orderData.pricePerUnit, 0), "/vial)"), variant: "discount", value: currencyFormatter(Math.abs(orderData.msrp *
                        (quantity - (orderData.applyFreeVials || 0)) -
                        orderData.pricePerUnit *
                            (quantity - (orderData.applyFreeVials || 0))), 2), hasExtraSpacing: true })),
                !calculatedPricing &&
                    isBulk &&
                    ((_m = orderData === null || orderData === void 0 ? void 0 : orderData.selectedBulkRate) === null || _m === void 0 ? void 0 : _m.discount) && (React.createElement(ReceiptLineItem, { label: (_p = (_o = orderData === null || orderData === void 0 ? void 0 : orderData.selectedBulkRate) === null || _o === void 0 ? void 0 : _o.discount) === null || _p === void 0 ? void 0 : _p.description, value: currencyFormatter((_r = (_q = orderData === null || orderData === void 0 ? void 0 : orderData.selectedBulkRate) === null || _q === void 0 ? void 0 : _q.discount) === null || _r === void 0 ? void 0 : _r.discountAmount, 2), hasExtraSpacing: true, variant: "discount" })),
                !calculatedPricing && orderData && (React.createElement(ReceiptLineItem, { label: "Subtotal", value: currencyFormatter(orderData.pricePerUnit *
                        (quantity - (orderData.applyFreeVials || 0)) -
                        bulkDiscount, 2), hasExtraSpacing: true })),
                !calculatedPricing && orderData && (React.createElement(Separator, { color: theme.colors.bg.base })),
                !calculatedPricing && orderData && (React.createElement(ReceiptLineItem, { label: "Estimated Total", value: currencyFormatter(orderData.pricePerUnit *
                        (quantity - (orderData.applyFreeVials || 0)) -
                        bulkDiscount, 2) })),
                !calculatedPricing && orderData && (React.createElement(ReceiptLineItem, { label: "Total Saved", value: currencyFormatter(Math.abs(orderData.msrp * quantity -
                        orderData.pricePerUnit * orderData.quantity +
                        bulkDiscount), 2), hasExtraSpacing: true, variant: "discount" }))));
        }
        return React.createElement(NoOrderSelectedText, null, "No order selected yet.");
    };
    var getPrimaryText = function () {
        var _a, _b, _c, _d;
        if (location.search === '?step=quantitySelection') {
            var amount = orderData.pricePerUnit * (quantity - ((_a = orderData === null || orderData === void 0 ? void 0 : orderData.applyFreeVials) !== null && _a !== void 0 ? _a : 0));
            if (((_b = orderData === null || orderData === void 0 ? void 0 : orderData.selectedBulkRate) === null || _b === void 0 ? void 0 : _b.discount) &&
                orderData.orderType === OrderQuantityType.BULK) {
                amount = amount - ((_d = (_c = orderData === null || orderData === void 0 ? void 0 : orderData.selectedBulkRate) === null || _c === void 0 ? void 0 : _c.discount) === null || _d === void 0 ? void 0 : _d.discountAmount);
            }
            return "".concat(primaryButtonText, ": ").concat(currencyFormatter(isNaN(amount) ? 0 : amount, 2));
        }
        return primaryButtonText;
    };
    var renderOrderDetails = !isMobile || (isMobile && (isReviewScreen || isOrderConfirmation));
    var renderStartOver = isMobile && !isQuantitySelect && !isOrderConfirmation;
    var renderRewardsOnly = isQuantitySelect && !!availableFreeVials;
    var renderSecondaryButtons = renderStartOver || isRewardVialSelection || renderRewardsOnly;
    return (React.createElement(OrderSummaryContainer, null,
        !isMobile && React.createElement(EvoLogo, { mb: 24 }),
        renderOrderDetails && (React.createElement(React.Fragment, null,
            React.createElement(OrderSummaryHeaderText, null, "Your Order"),
            renderPreapplyMessage ? (React.createElement(Typography, { mt: 32, variant: "caption", color: "link" }, "Promo code applied and will reflect at checkout.")) : (React.createElement(OrderSummarySubheaderText, null, "ORDER")),
            React.createElement(SelectedProductContainer, null,
                React.createElement(ProductLeftContainer, null,
                    React.createElement(JeuveauIcon, { src: jeuveauIcon }),
                    React.createElement(JeuveauLabel, null, "Jeuveau\u00AE")),
                React.createElement(JeuveauLabel, null,
                    quantity,
                    " Vial",
                    pluralize(quantity))),
            renderOrderSummary())),
        React.createElement(OrderSummaryButtonContainer, null,
            primaryButtonText && (React.createElement(Button, { onClick: function () { return onContinue(null, orderData.quantity); }, disabled: primaryButtonDisabled || anyLoading, size: "xlarge", "data-automation-id": "order-summary-flow-btn" }, anyLoading ? React.createElement(LoadingBubbles, null) : getPrimaryText())),
            renderSecondaryButtons && (React.createElement(SecondaryButtonContainer, { doubleContainer: renderStartOver && isRewardVialSelection },
                renderStartOver && (React.createElement(Button, { onClick: onRestart, size: "xlarge", variant: "tertiary", "data-automation-id": "order-summary-reward-skip-btn" }, "Restart Order")),
                isRewardVialSelection && hasPaidVials && (React.createElement(Button, { onClick: function () {
                        setOrderData(__assign(__assign({}, orderData), { applyFreeVials: 0 }));
                        navigate({ search: nextStep });
                    }, text: "Skip", size: "xlarge", variant: "tertiary", "data-automation-id": "order-summary-reward-skip-btn" }, "Skip")),
                renderRewardsOnly && (React.createElement(Button, { onClick: function () {
                        setOrderData(__assign(__assign({}, orderData), { liveUpgradeRate: null, pricePerUnit: quoteObj.pricing.defaultRate, quantity: 0, applyFreeVials: 0 }));
                        navigate({ search: nextStep });
                    }, size: "xlarge", variant: "tertiary" }, "Redeem Reward Vials Only"))))),
        isReviewScreen && (React.createElement(Typography, { variant: "footnote", color: "alt5", mt: 16 },
            "Reminder: All Orders are subject to the",
            ' ',
            React.createElement(TermLinks, { target: "_blank", href: "#" }, "Evolus Terms and Conditions of Sale"),
            ' ',
            "and the",
            ' ',
            React.createElement(TermLinks, { target: "_blank", href: "#" }, "Evolus Returned Goods Policy")))));
};
var OrderSummaryContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  background-color: white;\n  margin: 16px;\n\n  padding: 48px 32px;\n\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n    padding: 32px;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  background-color: white;\n  margin: 16px;\n\n  padding: 48px 32px;\n\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n    padding: 32px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var OrderSummaryHeaderText = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 1.5rem;\n  color: ", ";\n"], ["\n  font-size: 1.5rem;\n  color: ", ";\n"])), function (props) { return props.theme.colors.text.alt; });
var OrderSummarySubheaderText = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 0.875rem;\n  color: ", ";\n  font-weight: 600;\n  margin-top: 36px;\n"], ["\n  font-size: 0.875rem;\n  color: ", ";\n  font-weight: 600;\n  margin-top: 36px;\n"])), function (props) { return props.theme.colors.text.alt; });
var OrderSummaryButtonContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 48px;\n  display: flex;\n  flex-direction: column;\n\n  @media only screen and (max-width: ", "px) {\n    margin-top: 16px;\n  }\n"], ["\n  margin-top: 48px;\n  display: flex;\n  flex-direction: column;\n\n  @media only screen and (max-width: ", "px) {\n    margin-top: 16px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var SecondaryButtonContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  ", ";\n"], ["\n  margin-top: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  ", ";\n"])), function (props) { return props.doubleContainer && "justify-content: space-between"; });
var NoOrderSelectedText = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 0.875rem;\n  color: ", ";\n  margin-top: 12px;\n"], ["\n  font-size: 0.875rem;\n  color: ", ";\n  margin-top: 12px;\n"])), function (props) { return props.theme.colors.text.alt; });
var JeuveauIcon = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 26px;\n  height: 47px;\n  margin-right: 20px;\n"], ["\n  width: 26px;\n  height: 47px;\n  margin-right: 20px;\n"])));
var JeuveauLabel = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-weight: 500;\n  font-size: 1rem;\n"], ["\n  font-weight: 500;\n  font-size: 1rem;\n"])));
var SelectedProductContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 32px 0;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 32px 0;\n"])));
var ProductLeftContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var BulletItemContainer = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin-top: 10px;\n"], ["\n  margin-top: 10px;\n"])));
var LoadingContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  position: absolute;\n  height: 100%;\n  top: 0;\n  left: 0;\n  background-color: #ffffff90;\n  backdrop-filter: blur(2px);\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  position: absolute;\n  height: 100%;\n  top: 0;\n  left: 0;\n  background-color: #ffffff90;\n  backdrop-filter: blur(2px);\n"])));
var TermLinks = styled.a(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  all: inherit;\n  display: inline;\n  text-decoration: underline;\n  cursor: pointer;\n"], ["\n  all: inherit;\n  display: inline;\n  text-decoration: underline;\n  cursor: pointer;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
