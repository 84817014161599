var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Icon, Typography } from 'designSystem';
import { ListItemCard } from '../components/ListItemCard';
import phoneNumberFormatter from 'utils/phoneNumberFormatter';
import styled from 'styled-components';
export var RewardReceivedDetails = function (_a) {
    var _b, _c, _d, _e;
    var details = _a.details, injectors = _a.injectors, loading = _a.loading, processing = _a.processing, updateInjector = _a.updateInjector;
    var hasInjector = !!((_b = details.injector) === null || _b === void 0 ? void 0 : _b.name);
    var isBusCampaign = !!((_e = (_d = (_c = details.rewardPromotion) === null || _c === void 0 ? void 0 : _c.campaignId) === null || _d === void 0 ? void 0 : _d.includes) === null || _e === void 0 ? void 0 : _e.call(_d, 'BUS_20U'));
    var _f = __read(useState([]), 2), options = _f[0], setOptions = _f[1];
    var _g = __read(useState(false), 2), editing = _g[0], setEditing = _g[1];
    var _h = __read(useState(hasInjector
        ? { value: details.injector.uniqueId, label: details.injector.name }
        : null), 2), selectedInjector = _h[0], setSelectedInjector = _h[1];
    useEffect(function () {
        var injectorOptions = injectors.map(function (i) { return ({
            label: i.name,
            value: i.uniqueId,
        }); });
        setOptions(injectorOptions);
    }, [injectors]);
    var handleCreate = function (name) {
        var newOption = {
            value: undefined,
            label: name,
        };
        var newOptions = __spreadArray(__spreadArray([], __read(options.filter(function (i) { return !!i.value; })), false), [newOption], false);
        setOptions(newOptions);
        setSelectedInjector(newOption);
    };
    var renderSpecialist = function () {
        var specialistInfo = selectedInjector
            ? "Treated By ".concat(selectedInjector.label)
            : 'Add specialist information';
        if (!editing) {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement(Icon, { name: loading ? 'loading' : 'injector' }),
                    React.createElement(Typography, { variant: "headlineSmall" }, loading ? 'Loading...' : specialistInfo)),
                !loading && (React.createElement(SpecialistEditButton, { disabled: loading || processing, variant: "quaternary", onClick: function () { return setEditing(true); } }, "Edit"))));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement(Icon, { name: loading || processing ? 'loading' : 'injector' }),
                React.createElement(Dropdown, { searchable: true, selected: selectedInjector, formatAddLabel: "Add new Specialist", placeholder: "Specialist Name", options: options, onAddOption: handleCreate, onSelect: setSelectedInjector })),
            React.createElement(SpecialistEditButton, { variant: "quaternary", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var selected;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                selected = (selectedInjector === null || selectedInjector === void 0 ? void 0 : selectedInjector.value)
                                    ? injectors.find(function (i) { return i.uniqueId === selectedInjector.value; })
                                    : {
                                        name: selectedInjector.label,
                                        uniqueId: selectedInjector.value,
                                    };
                                return [4, updateInjector(selected)];
                            case 1:
                                _a.sent();
                                setEditing(false);
                                return [2];
                        }
                    });
                }); }, disabled: !selectedInjector || processing || loading }, "Save")));
    };
    var renderRewardAmount = function () {
        if (isBusCampaign) {
            return React.createElement(React.Fragment, null, "20 Units Free");
        }
        return React.createElement(React.Fragment, null,
            "$",
            details.rewardAmount,
            ".00");
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItemCard, null,
            React.createElement("div", null,
                React.createElement(Icon, { name: "user" }),
                React.createElement(Typography, { variant: "headlineSmall", display: "inline" },
                    details.consumerFirstName,
                    " ",
                    details.consumerLastName)),
            React.createElement(Typography, { variant: "headlineSmall", display: "inline", color: "success" },
                "+",
                details.creditsEarned,
                " Credit")),
        React.createElement(ListItemCard, null,
            React.createElement("div", null,
                React.createElement(Icon, { name: "phone" }),
                React.createElement(Typography, { variant: "headlineSmall", display: "inline" },
                    "+",
                    phoneNumberFormatter(details.phoneNumber)))),
        React.createElement(ListItemCard, null,
            React.createElement("div", null,
                React.createElement(Icon, { name: "present" }),
                React.createElement(Typography, { variant: "headlineSmall", display: "inline" }, "Reward Amount")),
            React.createElement(Typography, { variant: "headlineSmall", display: "inline", color: "success" }, renderRewardAmount())),
        React.createElement(ListItemCard, null,
            React.createElement(Typography, { variant: "headlineSmall", display: "inline" },
                "Given by ",
                details.staffFirstName,
                " ",
                details.staffLastName)),
        React.createElement(ListItemCard, null, renderSpecialist())));
};
var SpecialistEditButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  padding: 0 12px;\n"], ["\n  color: ", ";\n  padding: 0 12px;\n"])), function (props) { return props.theme.colors.text.alt; });
var templateObject_1;
