import React from 'react';
import { SpecialistSelectView } from './View';
var res = {
    "data": {
        "getInjectors": {
            "externalLookupSuccess": true,
            "injectors": [
                {
                    "name": "Injector 1",
                    "uniqueId": "123",
                    "externalId": "abc"
                },
                {
                    "name": "Injector 2",
                    "uniqueId": "456",
                    "externalId": "def"
                },
                {
                    "name": "Injector 3",
                    "uniqueId": "789",
                    "externalId": "ghi"
                }
            ]
        }
    }
};
export var SpecialistSelectContainer = function (_a) {
    var _b, _c;
    var initialVal = _a.initialVal, onSelect = _a.onSelect, handleRestart = _a.handleRestart;
    return (React.createElement(SpecialistSelectView, { handleRestart: handleRestart, initialVal: initialVal, onSelect: onSelect, injectors: (_c = (_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.getInjectors) === null || _c === void 0 ? void 0 : _c.injectors, loading: false }));
};
