var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { RewardStatusView } from './View';
import { useUser } from 'context';
import { ConsumerRewardEligibilityStatus, EnrollmentMethod, RewardAmountUpdateReason, } from 'types/codegen/hooks';
var res = {
    "data": {
        "lookupConsumerProfile": {
            "profile": {
                "status": "active",
                "enrolledAt": "2022-01-01T00:00:00Z",
                "enrollmentMethod": EnrollmentMethod.Sms,
                "email": "example@example.com",
                "sourceAccountId": "123456",
                "sourceFacilityId": "654321",
                "isOptedIn": true
            },
            "reward": {
                "earnedAt": "2022-01-02T00:00:00Z",
                "eligibleAt": "2022-01-03T00:00:00Z",
                "expiration": "2022-12-31T00:00:00Z",
                "eligibilityStatus": ConsumerRewardEligibilityStatus.Eligible,
                "rewardSource": "promotion",
                "rewardAmount": 100,
                "rewardAmountUpdateReason": RewardAmountUpdateReason.RefereeRewardRedemption,
                "isExtension": false
            },
            "promotion": {
                "amount": 50,
                "couponColor": "red",
                "campaignId": "campaign123"
            }
        }
    }
};
export var RewardStatusContainer = function (_a) {
    var _b;
    var phoneNumber = _a.phoneNumber, rewardReminderSent = _a.rewardReminderSent, onForward = _a.onForward, onRewardAction = _a.onRewardAction, noPromptRestart = _a.noPromptRestart, resentEnrollmentEmail = _a.resentEnrollmentEmail, onRemind = _a.onRemind, enrolledThisSession = _a.enrolledThisSession;
    var _c = useUser(), account = _c.account, facility = _c.facility;
    var facilityName = facility === null || facility === void 0 ? void 0 : facility.name;
    var _d = __read(useState(resentEnrollmentEmail !== null && resentEnrollmentEmail !== void 0 ? resentEnrollmentEmail : false), 2), resentEnrollmentByEmail = _d[0], setResentEnrollmentByEmail = _d[1];
    var handleSendRewardReminder = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            console.log('@TODO IPG Send Reward Reminder');
            return [2];
        });
    }); };
    var handleSendStatusReminder = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            console.log('@TODO IPG Send Status Reminder');
            return [2];
        });
    }); };
    var handleCreateReward = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            console.log('@TODO IPG Create Reward');
            return [2, null];
        });
    }); };
    var handleResendEnrollmentEmail = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            console.log('@TODO IPG Resend Enrollment Email');
            return [2, false];
        });
    }); };
    return (React.createElement(RewardStatusView, { onRewardAction: onRewardAction, consumerProfile: (_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.lookupConsumerProfile, enrolledThisSession: enrolledThisSession, phoneNumber: phoneNumber, stepLoading: false, sendLoading: false, facilityName: facilityName, rewardReminderSent: rewardReminderSent, noPromptRestart: noPromptRestart, onRemind: onRemind, resentEnrollmentByEmail: resentEnrollmentByEmail, handleSendRewardReminder: handleSendRewardReminder, handleSendStatusReminder: handleSendStatusReminder, handleCreateReward: handleCreateReward, handleResendEnrollmentEmail: handleResendEnrollmentEmail }));
};
