var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { createRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, LoadingBubbles, Typography } from 'designSystem';
import { passwordVerification, } from 'utils/passwordVerification';
import EvolusLogo from 'assets/evolus-logo-coral.svg';
import { useIsMounted } from 'hooks/useIsMounted';
import { useScreenSizing } from '../../../../../hooks';
import { PasswordRequirementsValidation } from 'components/PasswordRequirementsValidation';
import { NewPasswordInputs } from '../../../../NewPasswordInputs';
export var EnterCodeAndPassword = function (_a) {
    var setPasswordReset = _a.setPasswordReset, isLoading = _a.isLoading, setError = _a.setError, setIsLoading = _a.setIsLoading, emailAddress = _a.emailAddress, error = _a.error, renderedError = _a.renderedError, getVerificationCode = _a.getVerificationCode;
    var isMobile = useScreenSizing().isMobile;
    var _b = __read(useState({
        0: null,
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
    }), 2), codeObj = _b[0], setCodeObj = _b[1];
    var _c = __read(useState(''), 2), password = _c[0], setPassword = _c[1];
    var _d = __read(useState(''), 2), confirmPassword = _d[0], setConfirmPassword = _d[1];
    var _e = __read(useState([]), 2), codeInputRefs = _e[0], setCodeInputRefs = _e[1];
    var _f = __read(useState({
        minimum: false,
        uppercase: false,
        lowercase: false,
        number: false,
    }), 2), passwordRequirements = _f[0], setPasswordRequirements = _f[1];
    var _g = __read(useState(false), 2), allRequirementsMet = _g[0], setAllRequirementsMet = _g[1];
    var isMounted = useIsMounted();
    useEffect(function () {
        setCodeInputRefs(function (inputRefs) {
            return Array(6)
                .fill('')
                .map(function (_, i) { return inputRefs[i] || createRef(); });
        });
    }, []);
    var checkPasswordRequirements = function () {
        var verification = passwordVerification(password);
        if (password !== null) {
            setPasswordRequirements(verification.passwordRequirements);
            setAllRequirementsMet(verification.allTestsPassing);
        }
    };
    useEffect(function () {
        checkPasswordRequirements();
    }, [password]);
    var submit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var code;
        return __generator(this, function (_a) {
            if (password !== confirmPassword && isMounted) {
                return [2];
            }
            code = Object.values(codeObj).join('');
            if (isMounted) {
                setError(null);
                setIsLoading(true);
            }
            try {
                if (isMounted) {
                    setPasswordReset(true);
                }
            }
            catch (e) {
                if (isMounted) {
                    setError(e);
                }
            }
            finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
            return [2];
        });
    }); };
    var setInputFocus = function () {
        var _a, _b, _c, _d;
        var objKeys = Object.keys(codeObj);
        var emptyInputIndex = objKeys.findIndex(function (item) { return !codeObj[item]; });
        var previousInputIndex = objKeys.length - 1;
        if (emptyInputIndex === -1) {
            (_b = (_a = codeInputRefs[previousInputIndex]) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.focus();
        }
        else {
            (_d = (_c = codeInputRefs[emptyInputIndex]) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.focus();
        }
    };
    var handleInputBackspace = function (nativeEvent, id) {
        var _a, _b, _c;
        var currentIndex = parseInt(id, 0);
        var previousIndex = currentIndex - 1;
        var clearPreviousInput = function () {
            var _a, _b, _c, _d;
            if (codeInputRefs[previousIndex]) {
                (_b = (_a = codeInputRefs[currentIndex]) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.value = '';
                (_d = (_c = codeInputRefs[previousIndex]) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.focus();
            }
        };
        if (nativeEvent.keyCode === 8) {
            var nextIndex = currentIndex + 1;
            setError(null);
            if (nextIndex !== Object.keys(codeObj).length && nextIndex !== 1) {
                setCodeObj(__assign(__assign({}, codeObj), (_a = {}, _a[previousIndex] = null, _a)));
                clearPreviousInput();
                return;
            }
            if (codeObj[id] !== null) {
                setCodeObj(__assign(__assign({}, codeObj), (_b = {}, _b[id] = null, _b)));
                return;
            }
            setCodeObj(__assign(__assign({}, codeObj), (_c = {}, _c[id] = null, _c)));
            clearPreviousInput();
        }
    };
    var handleCodeInput = function (input, id) { return __awaiter(void 0, void 0, void 0, function () {
        var currentIndex;
        var _a, _b, _c;
        var _d, _e, _f, _g;
        return __generator(this, function (_h) {
            currentIndex = parseInt(id, 0);
            if (currentIndex === Object.keys(codeObj).length - 1 && input) {
                setCodeObj(__assign(__assign({}, codeObj), (_a = {}, _a[id] = input, _a)));
                return [2, (_e = (_d = codeInputRefs[currentIndex]) === null || _d === void 0 ? void 0 : _d.current) === null || _e === void 0 ? void 0 : _e.blur()];
            }
            if (!input) {
                return [2, setCodeObj(__assign(__assign({}, codeObj), (_b = {}, _b[id] = null, _b)))];
            }
            setCodeObj(__assign(__assign({}, codeObj), (_c = {}, _c[id] = input, _c)));
            return [2, (_g = (_f = codeInputRefs[currentIndex + 1]) === null || _f === void 0 ? void 0 : _f.current) === null || _g === void 0 ? void 0 : _g.focus()];
        });
    }); };
    var canSetPassword = function () {
        if (!password || !confirmPassword || !allRequirementsMet) {
            return true;
        }
        return false;
    };
    return (React.createElement(ChangePasswordContainer, null,
        React.createElement(LogoImage, { src: EvolusLogo }),
        React.createElement(Typography, { variant: isMobile ? 'titleLarge' : 'display' }, "1. Enter the verification code"),
        React.createElement(BodyTextContainer, null,
            React.createElement(Typography, { variant: isMobile ? 'body' : 'title', color: isMobile ? 'alt' : 'base' }, "If your email address is in our database, you will receive instructions to reset your password in a few minutes."),
            React.createElement(Typography, { mt: 32, variant: isMobile ? 'body' : 'title', color: isMobile ? 'alt' : 'base' },
                "Enter the verification code you receive in your email.",
                ' ')),
        React.createElement(InputOuterContainer, { onClick: function () { return setInputFocus(); } }, Object.keys(codeObj).map(function (id) { return (React.createElement(CodeInput, { maxLength: 1, placeholder: (Number(id) + 1).toString(), value: codeObj[id], onChange: function (e) { return handleCodeInput(e.target.value, id); }, onKeyDown: function (e) { return handleInputBackspace(e, id); }, ref: codeInputRefs[id], hasError: !!error })); })),
        renderedError,
        React.createElement(Typography, { variant: "body", color: "alt5", mb: 40 },
            "Didn't receive a code?",
            ' ',
            React.createElement(Link, { variant: "body", color: "link", onClick: function () {
                    setError(null);
                    getVerificationCode();
                } }, "Send Again")),
        React.createElement(Typography, { variant: isMobile ? 'titleLarge' : 'display' }, "2. Set your new password"),
        React.createElement(PasswordRequirementsValidation, __assign({}, passwordRequirements)),
        React.createElement(NewPasswordInputs, { password: password, passwordConfirmation: confirmPassword, onPasswordChange: setPassword, onConfirmationChange: setConfirmPassword }),
        React.createElement(Button, { mt: 50, onClick: function () { return submit(); }, disabled: canSetPassword() || isLoading, size: "xlarge", fullWidth: isMobile }, isLoading ? React.createElement(LoadingBubbles, null) : 'Set New Password')));
};
var Link = styled(Typography)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
var LogoImage = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 30%;\n  margin-bottom: 56px;\n"], ["\n  width: 30%;\n  margin-bottom: 56px;\n"])));
var CodeInput = styled.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ::placeholder,\n  ::-webkit-input-placeholder {\n    color: ", ";\n  }\n  :-ms-input-placeholder {\n    color: ", ";\n  }\n  width: calc((100% / 6) - 20px);\n  text-align: center;\n  font-size: 40px;\n  border: none;\n  border-bottom: 2px solid\n    ", ";\n  margin: 0 8px;\n  padding-left: 1px;\n  padding-right: 1px;\n"], ["\n  ::placeholder,\n  ::-webkit-input-placeholder {\n    color: ", ";\n  }\n  :-ms-input-placeholder {\n    color: ", ";\n  }\n  width: calc((100% / 6) - 20px);\n  text-align: center;\n  font-size: 40px;\n  border: none;\n  border-bottom: 2px solid\n    ", ";\n  margin: 0 8px;\n  padding-left: 1px;\n  padding-right: 1px;\n"])), function (props) { return props.theme.colors.text.alt5; }, function (props) { return props.theme.colors.text.alt5; }, function (props) {
    return (props === null || props === void 0 ? void 0 : props.hasError)
        ? props.theme.colors.text.link
        : props.theme.colors.text.alt5;
});
var InputOuterContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  margin: 48px 0 24px 0;\n"], ["\n  width: 100%;\n  margin: 48px 0 24px 0;\n"])));
var BodyTextContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  justify-content: flex-start;\n  display: flex;\n  flex-direction: column;\n  margin-top: 32px;\n"], ["\n  justify-content: flex-start;\n  display: flex;\n  flex-direction: column;\n  margin-top: 32px;\n"])));
var ChangePasswordContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  z-index: 10000;\n  position: relative;\n  flex-direction: column;\n  padding: 0 130px;\n  @media only screen and (max-width: ", "px) {\n    padding: 0;\n    align-items: flex-start;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: center;\n  z-index: 10000;\n  position: relative;\n  flex-direction: column;\n  padding: 0 130px;\n  @media only screen and (max-width: ", "px) {\n    padding: 0;\n    align-items: flex-start;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
