var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a;
import React from 'react';
import { Modal } from '../Modal';
import { ActivityType, } from 'types/codegen/hooks';
import { Icon, Typography } from 'designSystem';
import { ListItemCard } from './components/ListItemCard';
import { format } from 'date-fns';
import { VialsRedeemedDetails } from './variants/VialsRedeemed';
import { ManualCreditReceivedDetails } from './variants/ManualCreditReceived';
import { RewardReceivedDetails } from './variants/RewardReceived';
import { RewardVialExpirationDetails } from './variants/RewardVialExpiration';
import { EvolusAdjustmentDetails } from './variants/EvolusAdjustment';
import styled, { css } from 'styled-components';
import { addMobileStyles } from '../../../utils/addMobileStyles';
var DetailModalTypes = {
    REWARD_RECEIVED: 'REWARD_RECEIVED',
    MANUAL_CREDIT: 'MANUAL_CREDIT',
    EVOLUS_ADJUSTMENT: 'EVOLUS_ADJUSTMENT',
    VIAL_REDEMPTION: 'VIAL_REDEMPTION',
    REWARD_VIAL_EXPIRATION: 'REWARD_VIAL_EXPIRATION',
};
function getModalType(type) {
    switch (type) {
        case ActivityType.ManualCredit:
            return DetailModalTypes.MANUAL_CREDIT;
        case ActivityType.EvolusAdjustment:
            return DetailModalTypes.EVOLUS_ADJUSTMENT;
        case ActivityType.VialRedeemed:
            return DetailModalTypes.VIAL_REDEMPTION;
        case ActivityType.RewardVialExpiration:
            return DetailModalTypes.REWARD_VIAL_EXPIRATION;
        case ActivityType.CreditReceived:
        default:
            return DetailModalTypes.REWARD_RECEIVED;
    }
}
var ModalTitle = (_a = {},
    _a[DetailModalTypes.REWARD_RECEIVED] = 'Credit Received',
    _a[DetailModalTypes.MANUAL_CREDIT] = 'Credit Received',
    _a[DetailModalTypes.REWARD_VIAL_EXPIRATION] = 'Evolus Rewards 2020-2021 Credits Expired',
    _a[DetailModalTypes.EVOLUS_ADJUSTMENT] = 'Evolus Adjustment',
    _a[DetailModalTypes.VIAL_REDEMPTION] = 'Vial Redemption',
    _a);
export var TransactionDetailsModalView = function (_a) {
    var details = _a.details, injectors = _a.injectors, updateInjector = _a.updateInjector, loading = _a.loading, processing = _a.processing;
    var occurredOn = new Date(details.occurredOn);
    var modalType = getModalType(details.activityType);
    var renderContent = function () {
        switch (modalType) {
            case DetailModalTypes.VIAL_REDEMPTION:
                return React.createElement(VialsRedeemedDetails, { details: details });
            case DetailModalTypes.MANUAL_CREDIT:
                return React.createElement(ManualCreditReceivedDetails, { details: details });
            case DetailModalTypes.REWARD_RECEIVED:
                return (React.createElement(RewardReceivedDetails, { details: details, injectors: injectors, updateInjector: updateInjector, loading: loading, processing: processing }));
            case DetailModalTypes.REWARD_VIAL_EXPIRATION:
                return React.createElement(RewardVialExpirationDetails, { details: details });
            case DetailModalTypes.EVOLUS_ADJUSTMENT:
            default:
                return React.createElement(EvolusAdjustmentDetails, { details: details });
        }
    };
    return (React.createElement(Modal, null,
        React.createElement(HeaderContainer, null,
            React.createElement(Typography, { color: "alt5", variant: "sectionHeader" }, "Transaction Details"),
            React.createElement(Typography, { mt: 4, mb: 4, display: "block", variant: "titleLarge" }, modalType === 'REWARD_VIAL_EXPIRATION'
                ? details.facilityName + ' Credits Expired'
                : ModalTitle[modalType]),
            React.createElement(Typography, { color: "alt", variant: "title" }, details.facilityName)),
        React.createElement(ContentContainer, null,
            renderContent(),
            React.createElement(ListItemCard, null,
                React.createElement("div", null,
                    React.createElement(Icon, { name: "calendar" }),
                    React.createElement(Typography, { variant: "headlineSmall" }, format(occurredOn, 'PP p')))),
            React.createElement(ListItemCard, { onClick: function () { } },
                React.createElement(Typography, { variant: "headlineSmall" }, "Contact Evolus"),
                React.createElement(Icon, { name: "chevron-right" })))));
};
var HeaderContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n  margin-bottom: 32px;\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n  margin-bottom: 32px;\n"])));
var ContentContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  gap: 8px;\n  ", "\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  gap: 8px;\n  ", "\n"])), addMobileStyles(css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      flex: none;\n    "], ["\n      flex: none;\n    "])))));
var templateObject_1, templateObject_2, templateObject_3;
