var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import styled from 'styled-components';
import MapboxStatic from 'assets/mapbox-static-img.jpeg';
import { Typography } from 'designSystem';
mapboxgl.accessToken = 'pk.eyJ1IjoicmFtYmVhdSIsImEiOiJjbHg1Yjl2b2YxZnNwMmpxMWRpYmszOWllIn0.5WBBslkBjOWVJsYCR3exGw';
mapboxgl.workerClass = MapboxWorker;
export var MapView = function (_a) {
    var address = _a.address, facility = _a.facility;
    return (React.createElement(MapWrapper, null,
        React.createElement("div", { style: {
                width: '100%',
                position: 'absolute',
                height: '100%',
                backgroundPosition: 'center',
                backgroundImage: "url(".concat(MapboxStatic, ")"),
            } }),
        React.createElement(DeliveryDetails, null,
            React.createElement(Typography, { variant: "headlineSmall" }, facility.name),
            (facility === null || facility === void 0 ? void 0 : facility.shippingAddress.careOf) && (React.createElement(Typography, { variant: "bodySmall", color: "alt" }, facility.shippingAddress.careOf)),
            React.createElement(Typography, { variant: "bodySmall", color: "alt" }, address === null || address === void 0 ? void 0 :
                address.addressLine1,
                " ", address === null || address === void 0 ? void 0 :
                address.addressLine2),
            React.createElement(Typography, { variant: "bodySmall", color: "alt" }, address === null || address === void 0 ? void 0 :
                address.city,
                ", ", address === null || address === void 0 ? void 0 :
                address.state,
                " ", address === null || address === void 0 ? void 0 :
                address.zip))));
};
var MapWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 360px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n\n  @media only screen and (max-width: ", "px) {\n  }\n"], ["\n  width: 100%;\n  height: 360px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n\n  @media only screen and (max-width: ", "px) {\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var Map = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 360px;\n  width: 100%;\n\n  @media only screen and (max-width: ", "px) {\n  }\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 360px;\n  width: 100%;\n\n  @media only screen and (max-width: ", "px) {\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var DeliveryDetails = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  background: #fff;\n\n  z-index: 1;\n"], ["\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  background: #fff;\n\n  z-index: 1;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
