var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { PhoneNumberInput } from './components/CheckIn/PhoneNumberInput';
import { NumberNotSupported } from './components/CheckIn/NumberNotSupported';
import styled from 'styled-components';
import { StepEnum } from './Enums/StepEnum';
import { EnrollPatient } from './components/Enrollment/EnrollPatient';
import { PatientConsent } from './components/Enrollment/PatientConsent';
import { PatientConsentNotGiven } from './components/Enrollment/PatientConsentNotGiven';
import { EnrollmentProgress } from './components/Enrollment/EnrollmentProgress';
import { SendingMessage } from './components/Enrollment/SendingMessage';
import { RewardStatus } from './components/CheckIn/RewardStatus';
import { SpecialistSelect } from './components/CheckIn/SpecialistSelect';
import { CheckInAgreement } from './components/CheckIn/CheckInAgreement';
import { EmailAddressInput } from './components/Enrollment/EmailInput';
import { EnrollmentMethod } from 'types/codegen/hooks';
import SendMagicPhrase from './components/CheckIn/SendMagicPhrase';
import { ValidateMagicPhrase } from './components/CheckIn/ValidateMagicPhrase';
import { CheckInComplete } from './components/CheckIn/CheckInComplete';
import { RewardReminderSent } from './components/CheckIn/RewardReminderSent';
import { InlineEnrollment } from './components/Enrollment/InlineEnrollment';
import { MobileProgressStepper } from './components/MobileProgressStepper';
import { useScreenSizing } from 'hooks';
import { useUser } from "../../../../context";
export var CheckInView = function (_a) {
    var checkInData = _a.checkInData, setCheckInData = _a.setCheckInData, handleRestart = _a.handleRestart, noPromptRestart = _a.noPromptRestart;
    var location = useLocation();
    var isMobile = useScreenSizing().isMobile;
    var _b = __read(useState(false), 2), magicPhraseSent = _b[0], setPhraseSent = _b[1];
    var email = checkInData.email, enrollmentMethod = checkInData.enrollmentMethod, injector = checkInData.injector, optInStatus = checkInData.optInStatus, phoneNumber = checkInData.phoneNumber, retryLink = checkInData.retryLink, rewardAmount = checkInData.rewardAmount, checkInId = checkInData.checkInId, magicPhrase = checkInData.magicPhrase, rewardStatus = checkInData.rewardStatus, startDate = checkInData.startDate, endDate = checkInData.endDate, rewardReminderSent = checkInData.rewardReminderSent, sourceAccountId = checkInData.sourceAccountId, sourceFacilityId = checkInData.sourceFacilityId, earnedAt = checkInData.earnedAt, eligibleAt = checkInData.eligibleAt, rewardSource = checkInData.rewardSource, rewardAmountUpdateReason = checkInData.rewardAmountUpdateReason, expiration = checkInData.expiration, enrolledThisSession = checkInData.enrolledThisSession, isInitialEmail = checkInData.isInitialEmail, promoCampaignId = checkInData.promoCampaignId;
    var _c = useUser(), account = _c.account, switchAccount = _c.switchAccount;
    var onRewardAction = function (type) {
    };
    var renderStep = function () {
        switch (location.search) {
            case StepEnum.PhoneInput:
                return (React.createElement(PhoneNumberInput, { initialVal: phoneNumber, onForward: function (updates) {
                        return setCheckInData(__assign(__assign({}, checkInData), updates));
                    } }));
            case StepEnum.NumberNotSupported:
                return React.createElement(NumberNotSupported, { handleRestart: handleRestart });
            case StepEnum.EnrollPatient:
                return (React.createElement(EnrollPatient, { handleRestart: handleRestart, phoneNumber: phoneNumber }));
            case StepEnum.PatientConsent:
                return React.createElement(PatientConsent, { handleRestart: handleRestart });
            case StepEnum.ConsentNotGiven:
                return React.createElement(PatientConsentNotGiven, { handleRestart: handleRestart });
            case StepEnum.SendingMessage:
                return (React.createElement(SendingMessage, { handleRestart: handleRestart, email: email, phoneNumber: phoneNumber, retryLink: retryLink }));
            case StepEnum.EmailInput:
                return (React.createElement(EmailAddressInput, { handleRestart: handleRestart, initialVal: checkInData.email, onForward: function (newEmail) {
                        return setCheckInData(__assign(__assign({}, checkInData), { email: newEmail, enrollmentMethod: EnrollmentMethod.Email, retryLink: true, isInitialEmail: true }));
                    } }));
            case StepEnum.EnrollPatientForm:
                return (React.createElement(InlineEnrollment, { phoneNumber: phoneNumber, prefillEmail: checkInData.email, handleRestart: handleRestart, onForward: function () {
                        setCheckInData(__assign(__assign({}, checkInData), { enrolledThisSession: true, rewardReminderSent: true }));
                    } }));
            case StepEnum.EnrollmentProgress:
                return (React.createElement(EnrollmentProgress, { phoneNumber: phoneNumber, email: email, retryLink: retryLink, isInitialEmail: isInitialEmail, enrollmentMethod: enrollmentMethod, noPromptRestart: noPromptRestart, onEnroll: function () {
                        return setCheckInData(__assign(__assign({}, checkInData), { enrolledThisSession: true, rewardReminderSent: true }));
                    }, onResend: function () {
                        setCheckInData(__assign(__assign({}, checkInData), { retryLink: true, isInitialEmail: false }));
                    } }));
            case StepEnum.RewardStatus:
                return (React.createElement(RewardStatus, { enrolledThisSession: !!enrolledThisSession, onRewardAction: onRewardAction, onForward: function (updates) {
                        return setCheckInData(__assign(__assign({}, checkInData), updates));
                    }, rewardReminderSent: rewardReminderSent, phoneNumber: phoneNumber, noPromptRestart: noPromptRestart, onRemind: function (updates) {
                        return setCheckInData(__assign(__assign(__assign({}, checkInData), updates), { checkInComplete: true }));
                    } }));
            case StepEnum.RewardReminderSent:
                return (React.createElement(RewardReminderSent, { status: rewardStatus, noPromptRestart: noPromptRestart, phoneNumber: phoneNumber, email: email, enrollmentMethod: enrollmentMethod, rewardAmount: rewardAmount, startDate: startDate, endDate: endDate }));
            case StepEnum.SpecialistSelect:
                return (React.createElement(SpecialistSelect, { handleRestart: handleRestart, initialVal: checkInData.injector, onSelect: function (selectedInjector) {
                        return setCheckInData(__assign(__assign({}, checkInData), { injector: selectedInjector }));
                    } }));
            case StepEnum.CheckInAgreement:
                return (React.createElement(CheckInAgreement, { handleRestart: handleRestart, rewardAmount: rewardAmount, optInStatus: optInStatus }));
            case StepEnum.SendMagicPhrase:
                return (React.createElement(SendMagicPhrase, { onForward: function (checkId) {
                        onRewardAction('primary');
                        setCheckInData(__assign(__assign({}, checkInData), { checkInId: checkId }));
                        setPhraseSent(true);
                    }, handleRestart: handleRestart, phoneNumber: phoneNumber, injector: injector }));
            case StepEnum.ValidateMagicPhrase:
                return (React.createElement(ValidateMagicPhrase, { onRewardAction: onRewardAction, handleRestart: handleRestart, rewardAmount: rewardAmount, phoneNumber: phoneNumber, injector: injector, initialVal: magicPhrase, magicPhraseSent: magicPhraseSent, existingCheckInId: checkInId, onSubmitPhrase: function (phrase) {
                        setCheckInData(__assign(__assign({}, checkInData), { magicPhrase: phrase }));
                    }, onForward: function () {
                        var pointsEarned = (account === null || account === void 0 ? void 0 : account.rewardPointsEarned) + 1;
                        var rewardVials = account === null || account === void 0 ? void 0 : account.availableFreeVials;
                        if (pointsEarned % 9 === 0) {
                            rewardVials += 1;
                        }
                        var updatedAccount = __assign(__assign({}, account), { rewardPointsEarned: pointsEarned, availableFreeVials: rewardVials });
                        setCheckInData(__assign(__assign({}, checkInData), { checkInComplete: true }));
                        switchAccount(updatedAccount);
                        localStorage.setItem(account.id, JSON.stringify(updatedAccount));
                    } }));
            case StepEnum.CheckInComplete:
                return (React.createElement(CheckInComplete, { isBusCampaign: !!(promoCampaignId === null || promoCampaignId === void 0 ? void 0 : promoCampaignId.includes('BUS_20U')), rewardSource: rewardSource, rewardAmountUpdateReason: rewardAmountUpdateReason, phoneNumber: phoneNumber, email: email, enrollmentMethod: enrollmentMethod, rewardAmount: rewardAmount, noPromptRestart: noPromptRestart, onComplete: function () {
                        return setCheckInData(__assign(__assign({}, checkInData), { checkInComplete: true }));
                    } }));
        }
    };
    return (React.createElement(OuterContainer, null,
        isMobile && React.createElement(MobileProgressStepper, null),
        renderStep()));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  @media screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  @media screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var templateObject_1;
