var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useMemo, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { Button, IconButton } from 'designSystem';
import { SpeechBubble } from 'designSystem/components/Icon/icons';
import { Avatar } from 'designSystem/components/Avatar/Avatar';
import { Typography } from 'designSystem/components/Typography';
import { Icon } from 'designSystem/components/Icon';
import { Desktop, Mobile } from 'designSystem';
import { useUser } from 'context/UserContext';
import MyEvolusLogo from 'assets/my-evolus-logo-graphic.svg';
import JeuveauVial from 'assets/Jeuveau-vial.png';
import { NavigationDropdownMenu } from './NavigationDropdownMenu';
import { MobileNavigation } from './MobileNavigation';
import { IntercomButton } from '../../IntercomButton';
var NormalizeHeader = {
    orders: 'Orders',
    home: 'Home',
    evolux: 'Evolux',
    rewards: 'Rewards',
    settings: 'Account Settings',
    'profile-settings': 'User Profile Settings',
    offers: 'My Offers',
    invoices: 'Pay Invoice',
};
var DefaultLayout = function (_a) {
    var children = _a.children, theme = _a.theme;
    var navigate = useNavigate();
    var location = useLocation();
    var _b = useUser(), isAdmin = _b.isAdmin, account = _b.account;
    var _c = __read(useState(false), 2), showMenu = _c[0], setShowMenu = _c[1];
    var hasPastDue = false;
    var desktopTop = useCallback(function () { return (React.createElement(TopContainer, null,
        React.createElement(LogoContainer, { href: "/home" },
            React.createElement(EvolusLogoNav, { src: MyEvolusLogo })),
        React.createElement(TopBarContainer, null,
            React.createElement(NavHeader, null,
                React.createElement(TopBarInnerContainers, null,
                    React.createElement(Typography, { variant: "titleLarge" }, NormalizeHeader[location.pathname.split('/')[1]])),
                React.createElement(TopBarInnerContainers, null,
                    React.createElement(OutsideClickHandler, { onOutsideClick: function () { return setShowMenu(false); } },
                        React.createElement(UserMenu, { onClick: function () { return setShowMenu(!showMenu); }, "data-automation-id": 'navigation-user' },
                            React.createElement(Avatar, null),
                            React.createElement(Icon, { style: { marginLeft: 12 }, name: showMenu ? 'chevron-up' : 'chevron-down' })),
                        showMenu && (React.createElement(NavigationDropdownMenu, { setShowMenu: setShowMenu })))))))); }, [location, showMenu]);
    var menuItems = useMemo(function () { return [
        {
            icon: 'home',
            name: 'home',
            path: 'home',
            hidden: !isAdmin,
        },
        {
            icon: 'rewards',
            name: 'rewards',
            path: 'rewards',
        },
        {
            icon: 'orders',
            name: 'orders',
            path: 'orders',
            hidden: !isAdmin,
        },
        {
            icon: 'discount',
            name: 'My Offers',
            path: 'offers',
            hidden: !isAdmin,
        },
        {
            icon: 'give-card',
            name: 'Pay<br/>Invoice',
            path: 'invoices',
            hidden: !(account === null || account === void 0 ? void 0 : account.hasEvolusCredit) || !isAdmin,
            pastDue: hasPastDue,
        },
    ]; }, [isAdmin, account, hasPastDue]);
    var desktopSideBar = useCallback(function () { return (React.createElement(SideBar, null,
        React.createElement(MenuItemsContainer, null, menuItems.map(function (item) {
            if (item.hidden)
                return null;
            return (React.createElement(MenuItem, { key: item.name, onClick: function () { return navigate("/".concat(item.path)); }, "data-automation-id": "sidebar-navigation-".concat(item.name) },
                (item === null || item === void 0 ? void 0 : item.pastDue) && (React.createElement(PastDue, null,
                    React.createElement(Icon, { name: 'alert-red', size: 16 }))),
                React.createElement(Icon, { name: item.icon, size: 16, color: location.pathname.startsWith("/".concat(item.path))
                        ? theme.colors.text.link
                        : theme.colors.text.base }),
                React.createElement(MenuItemText, { active: location.pathname.startsWith("/".concat(item.path)), dangerouslySetInnerHTML: { __html: item.name } })));
        })),
        React.createElement(JeuveauButtonContainer, null,
            React.createElement(JeuveauButton, { onClick: function () { return navigate('/orders/buy?step=quantitySelection'); } },
                React.createElement(JeuveauIcon, { src: JeuveauVial }))))); }, [menuItems, location]);
    var renderDesktop = useCallback(function () { return (React.createElement(Root, null,
        React.createElement(IntercomButton, null),
        React.createElement(OuterContainer, null,
            React.createElement(InnerContainer, null,
                desktopTop(),
                React.createElement(BottomContainer, null,
                    desktopSideBar(),
                    React.createElement(MainContentContainer, null, children)))))); }, [desktopSideBar, desktopTop, children]);
    var renderMobile = useCallback(function () { return (React.createElement(Root, null,
        React.createElement(MobileNavigation, { open: showMenu, onClose: function () { return setShowMenu(false); } }),
        React.createElement(MobileTop, null,
            React.createElement(EvolusLogoIcon, { onClick: function () { return navigate('/home'); }, src: MyEvolusLogo }),
            React.createElement("div", null),
            React.createElement("div", null,
                React.createElement(IconButton, { onClick: function () { } },
                    React.createElement(SpeechBubble, { color: "inherit", height: "0.6em" })),
                React.createElement(Button, { onClick: function () { return setShowMenu(true); }, variant: "tertiary", noPadding: true },
                    React.createElement(Icon, { name: "menu" })))),
        children)); }, [showMenu]);
    return (React.createElement(Root, null,
        React.createElement(Mobile, null, renderMobile()),
        React.createElement(Desktop, null, renderDesktop())));
};
export default withTheme(DefaultLayout);
var EvolusLogoIcon = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var MobileTop = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 32px;\n  & > div:last-of-type {\n    display: flex;\n    align-items: center;\n    gap: 12px;\n    & > button:first-of-type {\n      border-radius: 100px;\n      margin-bottom: 4px;\n    }\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 32px;\n  & > div:last-of-type {\n    display: flex;\n    align-items: center;\n    gap: 12px;\n    & > button:first-of-type {\n      border-radius: 100px;\n      margin-bottom: 4px;\n    }\n  }\n"])));
var Root = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  min-height: 100%;\n  display: flex;\n  justify-content: center;\n  background-color: ", ";\n  color: ", ";\n  @media screen and (max-width: ", "px) {\n    display: flex;\n    flex-direction: column;\n    & > div:first-of-type {\n      flex: 1;\n    }\n  }\n"], ["\n  min-height: 100%;\n  display: flex;\n  justify-content: center;\n  background-color: ", ";\n  color: ", ";\n  @media screen and (max-width: ", "px) {\n    display: flex;\n    flex-direction: column;\n    & > div:first-of-type {\n      flex: 1;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.bg.base;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.text.base;
}, function (props) { return props.theme.breakpoints.md; });
var OuterContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 54px 54px 0 54px;\n  max-width: 1400px;\n  width: 100vw;\n  flex: 1;\n  align-self: flex-start;\n"], ["\n  padding: 54px 54px 0 54px;\n  max-width: 1400px;\n  width: 100vw;\n  flex: 1;\n  align-self: flex-start;\n"])));
var InnerContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n"])));
var LogoContainer = styled.a(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 80px;\n  display: flex;\n  justify-content: center;\n"], ["\n  width: 80px;\n  display: flex;\n  justify-content: center;\n"])));
var EvolusLogoNav = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 48px;\n  height: 35px;\n"], ["\n  width: 48px;\n  height: 35px;\n"])));
var BottomContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n"], ["\n  display: flex;\n  flex: 1;\n"])));
var SideBar = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  background-color: white;\n  height: 80vh;\n  width: 80px;\n  margin-right: 16px;\n  display: flex;\n  flex-direction: column;\n\n  padding: 35px 0;\n  margin-top: 40px;\n  align-items: center;\n  ", "\n"], ["\n  background-color: white;\n  height: 80vh;\n  width: 80px;\n  margin-right: 16px;\n  display: flex;\n  flex-direction: column;\n\n  padding: 35px 0;\n  margin-top: 40px;\n  align-items: center;\n  ", "\n"])), function (props) { return props.theme.elevation.base.elevation1; });
var TopBarContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  justify-content: space-between;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  margin-bottom: 14px;\n  margin-left: 32px;\n"], ["\n  justify-content: space-between;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  margin-bottom: 14px;\n  margin-left: 32px;\n"])));
var TopBarInnerContainers = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  position: relative;\n"], ["\n  align-items: center;\n  display: flex;\n  position: relative;\n"])));
var NavHeader = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  justify-content: space-between;\n"])));
var TopContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n"], ["\n  display: flex;\n  flex: 1;\n"])));
var MainContentContainer = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  width: 100%;\n"])));
var MenuItemsContainer = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 3;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 3;\n"])));
var MenuItem = styled.button(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  all: unset;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 14px 0;\n  height: 36px;\n"], ["\n  all: unset;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 14px 0;\n  height: 36px;\n"])));
var MenuItemText = styled(Typography).attrs({
    variant: 'caption',
})(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  color: ", ";\n  margin-top: 4px;\n  text-transform: capitalize;\n  text-align: center;\n"], ["\n  color: ", ";\n  margin-top: 4px;\n  text-transform: capitalize;\n  text-align: center;\n"])), function (props) {
    return props.active ? props.theme.colors.text.link : props.theme.colors.text.base;
});
var JeuveauButtonContainer = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  align-items: flex-end;\n"], ["\n  display: flex;\n  flex: 1;\n  align-items: flex-end;\n"])));
var JeuveauButton = styled.button(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  all: unset;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 48px;\n  height: 48px;\n  background-color: white;\n  border-radius: 100%;\n  ", "\n"], ["\n  all: unset;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 48px;\n  height: 48px;\n  background-color: white;\n  border-radius: 100%;\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.elevation.base.elevation1;
});
var JeuveauIcon = styled.img(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  width: 16px;\n  height: 29px;\n"], ["\n  width: 16px;\n  height: 29px;\n"])));
var UserMenu = styled.button(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  cursor: pointer;\n  padding: 0;\n  margin: 0;\n  background: transparent;\n  border: none;\n  outline: none;\n"], ["\n  cursor: pointer;\n  padding: 0;\n  margin: 0;\n  background: transparent;\n  border: none;\n  outline: none;\n"])));
var PastDue = styled.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  position: relative;\n  right: -14px;\n  top: 26px;\n"], ["\n  position: relative;\n  right: -14px;\n  top: 26px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22;
