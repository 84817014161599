var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { PaymentBannerComponent } from 'components/PaymentBanner';
import { OrderSummary } from './components/OrderSummary';
import { QuantitySelection } from './steps/QuantitySelection';
import { RewardVialSelection } from './steps/RewardVialSelection';
import { useLocation, useNavigate } from 'react-router';
import { AddressSelection } from './steps/AddressSelection';
import { DeliveryDateSelection } from './steps/DeliveryDateSelection';
import { PaymentMethodSelection } from './steps/PaymentMethodSelection';
import { ReviewOrderScreen } from './steps/ReviewOrderScreen';
import { OrderDiscountType, } from 'types/codegen/hooks';
import { OrderQuantityType } from 'types/enums/OrderQuantityTypeEnum';
import { PlaceholderLoading } from 'designSystem/components/PlaceholderLoading';
import { OrderConfirmation } from './steps/OrderConfirmation';
import OrderConfirmationImage from 'assets/order-success-graphic.svg';
import { Button, Desktop, Icon, Mobile, Typography } from 'designSystem';
import { useModal, useUser } from 'context';
import { useScreenSizing } from 'hooks';
import { EvoLogo } from './components/EvoLogo';
import ModalTypeEnum from '../../../../types/enums/ModalTypeEnum';
import { PaymentMethodType, getSelectedPaymentMethod, } from '../../../../utils/getSelectedPaymentMethod';
import { currencyFormatter } from 'utils/currencyFormatter';
import { OrderBlockBannerComponent } from 'components/OrderBlockBanner';
var OrderJeuveau = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var accountById = _a.accountById, availableFreeVials = _a.availableFreeVials, boostEnabled = _a.boostEnabled, calculatePricing = _a.calculatePricing, calculatedPricing = _a.calculatedPricing, calculatedPricingLoading = _a.calculatedPricingLoading, calculatedPricingError = _a.calculatedPricingError, setStoredPricing = _a.setStoredPricing, currentQuarterLoyalty = _a.currentQuarterLoyalty, vialsPurchasedLastQuarter = _a.vialsPurchasedLastQuarter, error = _a.error, isLoading = _a.isLoading, orderData = _a.orderData, quote = _a.quote, setOrderData = _a.setOrderData, pricingModel = _a.pricingModel, onRestart = _a.onRestart, vialsPurchasedThisQuarter = _a.vialsPurchasedThisQuarter, restarting = _a.restarting, setRestarting = _a.setRestarting, selectedPaymentMethod = _a.selectedPaymentMethod, setSelectedPaymentMethod = _a.setSelectedPaymentMethod;
    var location = useLocation();
    var navigate = useNavigate();
    var account = useUser().account;
    var isMobile = useScreenSizing().isMobile;
    var openModal = useModal().openModal;
    var _l = __read(useState((orderData === null || orderData === void 0 ? void 0 : orderData.orderType) || OrderQuantityType.BULK), 2), currentOrderType = _l[0], setCurrentOrderType = _l[1];
    var _m = __read(useState({}), 2), completedOrderData = _m[0], setCompletedOrderData = _m[1];
    var accountName = accountById === null || accountById === void 0 ? void 0 : accountById.name;
    var _o = __read(useState((_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.autoContinue), 2), shouldAutoContinue = _o[0], setShouldAutoContinue = _o[1];
    var _p = __read(useState((_c = location === null || location === void 0 ? void 0 : location.state) === null || _c === void 0 ? void 0 : _c.preFilledQuantity), 2), prefilledQuantity = _p[0], setPrefilledQuantity = _p[1];
    var _q = __read(useState((_e = (_d = location === null || location === void 0 ? void 0 : location.state) === null || _d === void 0 ? void 0 : _d.preapplyCode) !== null && _e !== void 0 ? _e : orderData === null || orderData === void 0 ? void 0 : orderData.preapplyCode), 2), preapplyCode = _q[0], setPreapplyCode = _q[1];
    var _r = __read(useState(false), 2), isAmountEnteredValid = _r[0], setIsAmountEnteredValid = _r[1];
    useEffect(function () {
        if (selectedPaymentMethod)
            onCalculate();
    }, [selectedPaymentMethod]);
    var hasPastDue = false;
    var hasOrderBlock = false;
    var sortedShippingMemo = useMemo(function () {
        var _a;
        var rates;
        if (!(quote === null || quote === void 0 ? void 0 : quote.defaultShipping) && !(orderData === null || orderData === void 0 ? void 0 : orderData.shippingRates)) {
            return [];
        }
        if (orderData === null || orderData === void 0 ? void 0 : orderData.shippingRates) {
            rates = orderData === null || orderData === void 0 ? void 0 : orderData.shippingRates;
        }
        if (quote === null || quote === void 0 ? void 0 : quote.defaultShipping) {
            rates = (_a = quote === null || quote === void 0 ? void 0 : quote.defaultShipping) === null || _a === void 0 ? void 0 : _a.rates;
        }
        var sorted = rates.sort(function (r1, r2) { return r1.quantity - r2.quantity; });
        return sorted;
    }, [quote, orderData]);
    var calculateShipping = useCallback(function () {
        if (!sortedShippingMemo.length) {
            return 0;
        }
        var higherIndex = sortedShippingMemo.findIndex(function (p) {
            return p.quantity > (orderData === null || orderData === void 0 ? void 0 : orderData.quantity) + ((orderData === null || orderData === void 0 ? void 0 : orderData.applyFreeVials) || 0);
        });
        var index = higherIndex === -1 ? sortedShippingMemo.length - 1 : higherIndex - 1;
        return sortedShippingMemo[index].rate;
    }, [sortedShippingMemo, orderData]);
    var onCalculate = useCallback(function (promotions, appliedMessage) { return __awaiter(void 0, void 0, void 0, function () {
        var freeVials, quantity, ppu, newPromos, promoAppliedMessage, shippingFee, primaryPaymentMethod, secondaryPaymentMethod, subtotal, taxEstimated, totalDiscounts, calculated;
        var _a, _b;
        return __generator(this, function (_c) {
            freeVials = (_a = orderData === null || orderData === void 0 ? void 0 : orderData.applyFreeVials) !== null && _a !== void 0 ? _a : 0;
            quantity = +(orderData === null || orderData === void 0 ? void 0 : orderData.quantity) + freeVials;
            ppu = (_b = orderData === null || orderData === void 0 ? void 0 : orderData.pricePerUnit) !== null && _b !== void 0 ? _b : quote.pricing.defaultRate;
            newPromos = promotions !== null && promotions !== void 0 ? promotions : orderData === null || orderData === void 0 ? void 0 : orderData.promotions;
            promoAppliedMessage = appliedMessage !== null && appliedMessage !== void 0 ? appliedMessage : orderData === null || orderData === void 0 ? void 0 : orderData.promoAppliedMessage;
            shippingFee = calculateShipping();
            setOrderData(__assign(__assign({}, orderData), { preapplyCode: preapplyCode, promotions: newPromos, promoAppliedMessage: promoAppliedMessage !== null && promoAppliedMessage !== void 0 ? promoAppliedMessage : null, shippingMethod: __assign(__assign({}, orderData.shippingMethod), { fee: shippingFee }), paymentMethod: selectedPaymentMethod }));
            primaryPaymentMethod = getSelectedPaymentMethod(selectedPaymentMethod, PaymentMethodType.primary);
            secondaryPaymentMethod = getSelectedPaymentMethod(selectedPaymentMethod, PaymentMethodType.secondary);
            subtotal = quantity * ppu + shippingFee;
            taxEstimated = subtotal * 0.0875;
            totalDiscounts = orderData.msrp * quantity - subtotal;
            calculated = {
                discounts: [
                    {
                        description: 'Discounts',
                        discountId: '1',
                        type: OrderDiscountType.CustomPricing,
                        total: quantity * ppu,
                        percentage: (610 - ppu) / 610,
                        percentageOf: 'total',
                    },
                ],
                shipping: {
                    fee: shippingFee,
                },
                lineItems: [
                    {
                        product: {},
                    },
                ],
                listPrice: 610,
                subtotal: subtotal,
                taxEstimated: taxEstimated,
                totalDiscounts: totalDiscounts,
                totalBeforeDiscounts: subtotal + taxEstimated,
                totalEstimated: subtotal + taxEstimated,
            };
            setStoredPricing(__assign(__assign({}, calculated), { inputDisplay: calculated }));
            return [2, calculated];
        });
    }); }, [
        setOrderData,
        account,
        calculatePricing,
        orderData,
        quote,
        calculateShipping,
        preapplyCode,
        selectedPaymentMethod,
    ]);
    var calculateTotalSaved = useCallback(function (inputDisplay) {
        if (orderData.applyFreeVials) {
            if (inputDisplay.discounts.filter(function (d) { return d.type === 'CUSTOM_PRICING'; })[0]
                .total > 0) {
                return (inputDisplay.totalDiscounts -
                    (orderData.applyFreeVials * orderData.msrp -
                        orderData.applyFreeVials * orderData.pricePerUnit));
            }
            return (Math.abs(inputDisplay.discounts.filter(function (d) { return d.type === 'CUSTOM_PRICING'; })[0]
                .total) +
                inputDisplay.totalDiscounts +
                (orderData.applyFreeVials * orderData.msrp -
                    orderData.applyFreeVials * orderData.pricePerUnit));
        }
        if (inputDisplay.discounts.filter(function (d) { return d.type === 'CUSTOM_PRICING'; })[0]
            .total > 0) {
            return inputDisplay.totalDiscounts;
        }
        return (Math.abs(inputDisplay.discounts.filter(function (d) { return d.type === 'CUSTOM_PRICING'; })[0]
            .total) + inputDisplay.totalDiscounts);
    }, [orderData]);
    var onApplyPromo = useCallback(function (promo, appliedMessage) { return __awaiter(void 0, void 0, void 0, function () {
        var newPromos;
        return __generator(this, function (_a) {
            newPromos = __spreadArray(__spreadArray([], __read(orderData.promotions.filter(function (_a) {
                var type = _a.type;
                return type !== 'PROMO_CODE';
            })), false), [
                promo,
            ], false);
            setOrderData(__assign(__assign({}, orderData), { promotions: newPromos, promoAppliedMessage: appliedMessage }));
            return [2];
        });
    }); }, [
        onCalculate,
        orderData,
        setOrderData,
        currentQuarterLoyalty,
        calculateTotalSaved,
    ]);
    var onRemovePromo = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var newPromos;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newPromos = orderData.promotions.filter(function (_a) {
                        var type = _a.type;
                        return type !== 'PROMO_CODE';
                    });
                    setOrderData(__assign(__assign({}, orderData), { promotions: newPromos, promoAppliedMessage: null }));
                    return [4, onCalculate(newPromos)];
                case 1:
                    _a.sent();
                    return [2];
            }
        });
    }); }, [onCalculate, orderData, setOrderData]);
    var handleRestart = useCallback(function () {
        openModal(ModalTypeEnum.RESTART_ORDER, {
            onRestart: function () {
                onRestart();
                setPreapplyCode(null);
                setPrefilledQuantity(null);
                setShouldAutoContinue(false);
            },
        });
    }, [onRestart, openModal]);
    useEffect(function () {
        if (!location.search) {
            navigate('?step=quantitySelection', { replace: true });
        }
    }, [location.search]);
    useEffect(function () {
        if (quote && restarting) {
            setOrderData(__assign(__assign({}, orderData), { accountName: accountName, availableFreeVials: availableFreeVials, achDiscountPercentage: quote.achDiscountPercentage, defaultRate: quote.pricing.defaultRate, msrp: quote.pricing.msrp, shippingRates: quote.defaultShipping.rates, shippingDates: quote.availableDeliveryDates, promotions: [], promoAppliedMessage: null, shippingMethod: __assign(__assign({}, orderData.shippingMethod), { serviceId: quote.defaultShipping.serviceId, shipperName: quote.defaultShipping.shipperName, serviceName: quote.defaultShipping.serviceName }), vialsPurchasedThisQuarter: vialsPurchasedThisQuarter }));
            setRestarting(false);
        }
    }, [quote, restarting]);
    var normalizedHeaderText = {
        A_LA_CARTE: 'A La Carte',
        BULK_ORDER: 'By the Bundle',
    };
    var getEvoluxSummary = function (loyaltyLevel) {
        var previousLevel = currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.startOfQuarterPricedWith;
        var currentLevel = currentQuarterLoyalty === null || currentQuarterLoyalty === void 0 ? void 0 : currentQuarterLoyalty.pricedWith;
        var isUpgrade = (currentLevel === null || currentLevel === void 0 ? void 0 : currentLevel.name) !== loyaltyLevel &&
            loyaltyLevel > (previousLevel === null || previousLevel === void 0 ? void 0 : previousLevel.level);
        var isEligible = vialsPurchasedLastQuarter >= 30;
        var isRetained = previousLevel.level === loyaltyLevel;
        return {
            isUpgrade: isUpgrade,
            isRetained: isRetained,
            isBoostEligible: (boostEnabled && isEligible) || false,
        };
    };
    var steps = {
        '?step=quantitySelection': {
            element: (React.createElement(QuantitySelection, { autoContinue: shouldAutoContinue, prefillQuantity: prefilledQuantity, accountName: accountName, currentOrderType: currentOrderType, setCurrentOrderType: setCurrentOrderType, setOrderData: setOrderData, orderData: orderData, availableFreeVials: availableFreeVials, quoteObj: quote, currentQuarterLoyalty: currentQuarterLoyalty, vialsPurchasedLastQuarter: vialsPurchasedLastQuarter, boostEnabled: boostEnabled, getEvoluxSummary: getEvoluxSummary, pricingModel: pricingModel })),
            label: "Order ".concat(normalizedHeaderText[currentOrderType]),
            headerText: "Place ".concat(currentOrderType === OrderQuantityType.BULK
                ? 'a bundle '
                : 'an A La Carte ', "\n            order below or order ").concat(currentOrderType === OrderQuantityType.BULK
                ? 'A La Carte '
                : 'by the bundle ', "\n            using the link at the top of this page."),
            subHeader: "Order by 4pm PST to ship same day (Monday \u2014 Thursday).",
            primaryButtonText: 'Proceed To Checkout',
            primaryButtonDisabled: !(orderData === null || orderData === void 0 ? void 0 : orderData.quantity),
            nextStep: availableFreeVials
                ? '?step=rewardVialSelection'
                : '?step=addressSelection',
        },
        '?step=rewardVialSelection': {
            element: (React.createElement(RewardVialSelection, { orderData: orderData, setOrderData: setOrderData })),
            label: 'Redeem Reward Vials',
            headerText: 'Apply your reward vials to this order',
            subHeader: 'Note: Reward vials will not count towards the accounts Evolux™ status',
            primaryButtonText: 'Add Reward Vials',
            primaryButtonDisabled: !orderData.applyFreeVials,
            nextStep: '?step=addressSelection',
        },
        '?step=addressSelection': {
            element: (React.createElement(AddressSelection, { facilities: accountById === null || accountById === void 0 ? void 0 : accountById.facilities, orderData: orderData, setOrderData: setOrderData })),
            label: 'Select an Address',
            headerText: 'Select which facility will receive this order.',
            primaryButtonText: 'Confirm Address',
            primaryButtonDisabled: !((_f = orderData === null || orderData === void 0 ? void 0 : orderData.facility) === null || _f === void 0 ? void 0 : _f.id),
            nextStep: '?step=deliveryDateSelection',
        },
        '?step=deliveryDateSelection': {
            element: (React.createElement(DeliveryDateSelection, { orderData: orderData, setOrderData: setOrderData })),
            label: 'Select Delivery Options',
            headerText: "Select delivery date and service to receive your order. ".concat(orderData.fee === 0 ? 'Shipping is free!' : ''),
            primaryButtonText: 'Confirm Delivery',
            primaryButtonDisabled: !((_g = orderData === null || orderData === void 0 ? void 0 : orderData.shippingMethod) === null || _g === void 0 ? void 0 : _g.requestedDeliveryDate),
            nextStep: '?step=paymentMethodSelection',
        },
        '?step=paymentMethodSelection': {
            element: (React.createElement(PaymentMethodSelection, { selectedPaymentMethod: selectedPaymentMethod, calculatedPricing: calculatedPricing, setSelectedPaymentMethod: setSelectedPaymentMethod, orderData: orderData, setOrderData: setOrderData, onApplyPromo: onApplyPromo, onRemovePromo: onRemovePromo, paidVials: (_h = orderData === null || orderData === void 0 ? void 0 : orderData.quantity) !== null && _h !== void 0 ? _h : 0, preapplyCode: preapplyCode, setIsAmountEnteredValid: setIsAmountEnteredValid, isAmountEnteredValid: isAmountEnteredValid, calculatedPricingLoading: calculatedPricingLoading })),
            label: 'Select Payment Method',
            headerText: 'Select payment method for this order and enter amount. Remainder will be put on Evolus Credit. ',
            subHeader: 'Payment cannot proceed if there is not enough credit available for remainder.',
            primaryButtonText: "Confirm ".concat(isMobile
                ? currencyFormatter((_j = calculatedPricing === null || calculatedPricing === void 0 ? void 0 : calculatedPricing.inputDisplay) === null || _j === void 0 ? void 0 : _j.totalEstimated, 2)
                : '', " Payment"),
            primaryButtonDisabled: !isAmountEnteredValid,
            nextStep: '?step=reviewOrderScreen',
        },
        '?step=reviewOrderScreen': {
            element: (React.createElement(ReviewOrderScreen, { paidVials: (_k = orderData === null || orderData === void 0 ? void 0 : orderData.quantity) !== null && _k !== void 0 ? _k : 0, orderData: orderData, setOrderData: setOrderData, calculatedPricingLoading: calculatedPricingLoading })),
            label: 'Review Order',
            headerText: 'Review your order.',
            primaryButtonText: 'Place Order',
            primaryButtonDisabled: false,
            nextStep: '?step=orderConfirmation',
        },
        '?step=orderConfirmation': {
            element: (React.createElement(OrderConfirmation, { order: completedOrderData === null || completedOrderData === void 0 ? void 0 : completedOrderData.order, orderDisplay: completedOrderData === null || completedOrderData === void 0 ? void 0 : completedOrderData.orderDisplay })),
            label: 'Thanks for your order!',
            headerText: 'Woohoo! Your payment was successful and your order is complete. We’re working on your order and will let you know when it ships. An order summary will be in your inbox shortly.',
        },
    };
    var isQuantitySelect = location.search === '?step=quantitySelection';
    var isAddressSelect = location.search === '?step=addressSelection';
    var isOrderConfirmation = location.search === '?step=orderConfirmation';
    var isReviewScreen = location.search === '?step=reviewOrderScreen';
    var isPaymentSelect = location.search === '?step=paymentMethodSelection';
    var isDeliverySelection = location.search === '?step=deliveryDateSelection';
    var isrewardVialSelection = location.search === '?step=rewardVialSelection';
    var renderPreapplyMessage = !!preapplyCode &&
        (isAddressSelect ||
            isDeliverySelection ||
            isPaymentSelect ||
            isReviewScreen);
    var renderStartOver = !isQuantitySelect && !isOrderConfirmation;
    var renderDesktop = useCallback(function () { return (React.createElement(React.Fragment, null,
        React.createElement(EvoLogo, { mb: 16 }),
        React.createElement(StepHeaderContainer, null,
            React.createElement(TopBarContainer, null,
                React.createElement(SectionHeaderText, null, "BUY NOW"),
                renderStartOver && (React.createElement(Button, { size: "small", variant: "secondary", startIcon: React.createElement(Icon, { name: "switch" }), onClick: handleRestart }, "Restart Order"))),
            React.createElement(Typography, { variant: "titleLarge", mb: 16 }, steps[location.search].label),
            React.createElement(SubtextContainer, null,
                React.createElement(Typography, { variant: "body", color: "alt" }, steps[location.search].headerText),
                steps[location.search].subHeader && (React.createElement(Typography, { variant: "body", color: "alt" }, steps[location.search].subHeader)))),
        location.search === '?step=orderConfirmation' && (React.createElement(Image, { src: OrderConfirmationImage })),
        steps[location.search].element)); }, [steps, location, renderStartOver, handleRestart]);
    var renderLoader = isLoading || error;
    var renderMobile = useCallback(function () { return (React.createElement(React.Fragment, null,
        !isQuantitySelect && (React.createElement(React.Fragment, null,
            React.createElement(EvoLogo, { mb: 16 }),
            React.createElement(StepHeaderContainer, null,
                React.createElement(Typography, { variant: "titleLarge", mb: 16 }, steps[location.search].label),
                React.createElement(SubtextContainer, null,
                    React.createElement(Typography, { variant: "body", color: "alt" }, steps[location.search].headerText),
                    renderPreapplyMessage && (React.createElement(Typography, { mt: 16, display: "block", variant: "caption", color: "link" }, "Promo code applied and will reflect at checkout.")),
                    steps[location.search].subHeader && (React.createElement(Typography, { variant: "body", color: "alt" }, steps[location.search].subHeader)))))),
        isOrderConfirmation && React.createElement(Image, { src: OrderConfirmationImage }),
        steps[location.search].element)); }, [
        steps,
        location,
        isQuantitySelect,
        renderPreapplyMessage,
        isOrderConfirmation,
    ]);
    var renderBanner = function () {
        if (hasOrderBlock &&
            (isPaymentSelect ||
                isDeliverySelection ||
                isAddressSelect ||
                isReviewScreen ||
                isOrderConfirmation ||
                isrewardVialSelection ||
                isQuantitySelect)) {
            return React.createElement(OrderBlockBannerComponent, null);
        }
        else if (hasPastDue) {
            return React.createElement(PaymentBannerComponent, null);
        }
    };
    return (React.createElement(OuterContainer, null,
        location.search && (React.createElement(React.Fragment, null,
            React.createElement(StepWrapper, null,
                (hasPastDue || hasOrderBlock) && (React.createElement(PaymentBannerWrapper, null, renderBanner())),
                React.createElement(StepContainer, null, renderLoader ? (React.createElement(PlaceholderLoadingContainer, null,
                    React.createElement(PlaceholderLoading, { size: "small" }))) : (React.createElement(React.Fragment, null,
                    React.createElement(Desktop, null, renderDesktop()),
                    React.createElement(Mobile, null, renderMobile()))))))),
        location.search !== '?step=orderConfirmation' ? (React.createElement(React.Fragment, null,
            isMobile && (React.createElement(DividerContainer, null,
                React.createElement(Divider, null))),
            React.createElement(OrderSummary, { calculateTotalSaved: calculateTotalSaved, autoContinue: shouldAutoContinue, prefillQuantity: prefilledQuantity, renderPreapplyMessage: renderPreapplyMessage, error: error, pricingModel: pricingModel, availableFreeVials: availableFreeVials, currentOrderType: currentOrderType, orderData: orderData, setOrderData: setOrderData, quoteObj: quote, getEvoluxSummary: getEvoluxSummary, nextStep: location.search && steps[location.search].nextStep, primaryButtonText: location.search && steps[location.search].primaryButtonText, primaryButtonDisabled: location.search && steps[location.search].primaryButtonDisabled, completedOrderData: completedOrderData, setCompletedOrderData: setCompletedOrderData, boostEnabled: boostEnabled, calculatePricing: onCalculate, calculatedPricing: calculatedPricing, calculatedPricingLoading: calculatedPricingLoading, calculatedPricingError: calculatedPricingError, onRestart: handleRestart, selectedPaymentMethod: selectedPaymentMethod }))) : null));
};
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var Image = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 250px;\n  position: absolute;\n  top: 20px;\n  right: 140px;\n\n  @media only screen and (max-width: ", "px) {\n    position: relative;\n    top: 0;\n    right: 0;\n    width: 100%;\n  }\n"], ["\n  width: 250px;\n  position: absolute;\n  top: 20px;\n  right: 140px;\n\n  @media only screen and (max-width: ", "px) {\n    position: relative;\n    top: 0;\n    right: 0;\n    width: 100%;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var StepContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex: 3;\n  flex-direction: column;\n  justify-content: flex-start;\n  background-color: white;\n\n  padding: 48px;\n  position: relative;\n\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n    padding: 32px;\n    padding-bottom: 0;\n    ", ";\n  }\n"], ["\n  display: flex;\n  flex: 3;\n  flex-direction: column;\n  justify-content: flex-start;\n  background-color: white;\n\n  padding: 48px;\n  position: relative;\n\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n    padding: 32px;\n    padding-bottom: 0;\n    ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return props.theme.elevation.base.elevation2; });
var StepHeaderContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n"], ["\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n"])));
var TopBarContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"])));
var SectionHeaderText = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 0.875rem;\n  color: ", ";\n  font-weight: 600;\n"], ["\n  font-size: 0.875rem;\n  color: ", ";\n  font-weight: 600;\n"])), function (props) { return props.theme.colors.text.alt5; });
var SubtextContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-bottom: 32px;\n  width: 60%;\n  @media only screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"], ["\n  margin-bottom: 32px;\n  width: 60%;\n  @media only screen and (max-width: ", "px) {\n    width: 100%;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var DividerContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background: ", ";\n  padding: 0 32px;\n"], ["\n  background: ", ";\n  padding: 0 32px;\n"])), function (props) { return props.theme.colors.basic.white; });
var Divider = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  height: 2px;\n  background: #f3f6f6;\n"], ["\n  height: 2px;\n  background: #f3f6f6;\n"])));
var StepWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-top: 16px;\n  flex: 3;\n\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n    margin-top: 20px;\n    ", ";\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-top: 16px;\n  flex: 3;\n\n  @media only screen and (max-width: ", "px) {\n    margin: 0;\n    margin-top: 20px;\n    ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
}, function (props) { return props.theme.elevation.base.elevation2; });
var PaymentBannerWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  margin: 0;\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    z-index: 1;\n  }\n"], ["\n  display: flex;\n  margin: 0;\n\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n    z-index: 1;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.md;
});
var PlaceholderLoadingContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: 'flex';\n  justifycontent: 'center';\n  alignitems: 'center';\n  height: '60vh';\n"], ["\n  display: 'flex';\n  justifycontent: 'center';\n  alignitems: 'center';\n  height: '60vh';\n"])));
export default OrderJeuveau;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
