var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import CurrentEvoluxLevel from 'components/CurrentEvoluxLevel';
import { Typography } from 'designSystem';
import React from 'react';
import EvoluxBenefitsWidget from 'scenes/HomeScreen/components/EvoluxBenefitsWidget';
import styled from 'styled-components';
import { Modal } from '../Modal';
import { useScreenSizing } from '../../../hooks';
var EvoluxLevelsList = function (_a) {
    var _b = _a.levels, levels = _b === void 0 ? [] : _b, _c = _a.currentLevel, currentLevel = _c === void 0 ? 'LEVEL_2' : _c;
    var isMobile = useScreenSizing().isMobile;
    return (React.createElement(Modal, { size: "large", scrollable: true },
        React.createElement(OuterContainer, null,
            React.createElement(Typography, { display: "block", variant: "sectionHeader", color: "alt5" }, "EVOLUX AT A GLANCE"),
            React.createElement(Typography, { mb: isMobile ? 0 : 32, variant: isMobile ? 'headline' : 'titleLarge' }, "Your Evolux Level"),
            levels.map(function (level) { return (React.createElement(CurrentEvoluxLevel, { context: "LEVELS_LIST", currentLevelOverride: level === null || level === void 0 ? void 0 : level.name, locked: level.name > currentLevel, level: level })); }),
            React.createElement(BenefitsContainer, null,
                React.createElement(EvoluxBenefitsWidget, null),
                React.createElement(Typography, { mb: 16, display: "block", variant: "footnote", color: "alt5" }, "A qualifying vial must be purchased and does not include free or promotional product."),
                React.createElement(Typography, { variant: "footnote", color: "alt5" }, "If an order size unlocks an instant upgrade, the associated discount will be applied to the same order. If you unlock an instant upgrade in a given quarter, your highest level achieved will be established for that quarter.")))));
};
export default EvoluxLevelsList;
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  overflow: auto;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  overflow: auto;\n"])));
var BenefitsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 16px;\n"], ["\n  margin: 16px;\n"])));
var templateObject_1, templateObject_2;
