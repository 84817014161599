var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Desktop, Typography } from 'designSystem';
import React from 'react';
import CheckInProgress from '../../CheckInProgress';
import { StepContainer } from '../../StepContainer';
import { useNavigate } from 'react-router';
import { EnrollmentMethod, } from 'types/codegen/hooks';
import { TextMessageBlock } from '../../TextMessageBlock';
import { EmailMessageBlock } from '../../EmailMessageBlock';
import { AvailableRewardCard } from '../AvailableRewardCard';
import styled, { css } from 'styled-components';
import { useScreenSizing } from 'hooks';
import { customBreakpointStyles } from 'utils/customBreakpointStyles';
import { PhoneNumber } from '../../PhoneNumber';
import { TwentyUnitsCard } from '../RewardStatus/components/TwentyUnitsCard';
var messageSent = function (_a) {
    var enrollmentMethod = _a.enrollmentMethod, email = _a.email, phoneNumber = _a.phoneNumber;
    if (enrollmentMethod === EnrollmentMethod.Email) {
        return (React.createElement(React.Fragment, null,
            "Email successfully sent to ",
            React.createElement("b", null, email)));
    }
    return (React.createElement(React.Fragment, null,
        "Text successfully sent to",
        ' ',
        React.createElement("b", null,
            React.createElement(PhoneNumber, { phoneNumber: phoneNumber }))));
};
export var CheckInComplete = function (_a) {
    var onComplete = _a.onComplete, rewardAmount = _a.rewardAmount, isBusCampaign = _a.isBusCampaign, enrollmentMethod = _a.enrollmentMethod, noPromptRestart = _a.noPromptRestart, phoneNumber = _a.phoneNumber, email = _a.email, rewardAmountUpdateReason = _a.rewardAmountUpdateReason, rewardSource = _a.rewardSource;
    var navigate = useNavigate();
    var isMobile = useScreenSizing().isMobile;
    var parsedReward = Number(rewardAmount);
    var creditsEarned = parsedReward / 40;
    var newProfileFromReferral = rewardSource === 'NEW_PROFILE_FROM_REFERRAL';
    var isEmailEnrollment = enrollmentMethod === EnrollmentMethod.Email;
    var completeCheckIn = function () {
    };
    var renderCard = function () {
        if (isBusCampaign) {
            return React.createElement(TwentyUnitsCard, null);
        }
        return (React.createElement(AvailableRewardCard, { newProfileFromReferral: newProfileFromReferral, rewardAmountUpdateReason: rewardAmountUpdateReason, rewardAmount: parsedReward, isComplete: true }));
    };
    var message = isBusCampaign
        ? 'Great News! 🎉 You just qualified for a special promotion. Your specialist should be giving you up to 20 units for free today!'
        : "Reward redeemed! \uD83C\uDF89 Your specialist should now be giving you $".concat(40, " off today's treatment...");
    return (React.createElement(React.Fragment, null,
        React.createElement(StepContainer, { hideRestart: true, label: "Check-In Complete", headerText: isMobile
                ? ''
                : 'Check-in is complete. Please remember to reward the patient.' },
            React.createElement(OuterContainer, null,
                React.createElement(LeftContainer, null,
                    isMobile && renderCard(),
                    React.createElement(Typography, { mt: isMobile ? 24 : 0, mb: 20, variant: "body" }, messageSent({ enrollmentMethod: enrollmentMethod, phoneNumber: phoneNumber, email: email })),
                    isEmailEnrollment ? (React.createElement(EmailMessageBlock, { fontSize: "large", message: message })) : (React.createElement(TextMessageBlock, { message: message })),
                    React.createElement(Desktop, null,
                        React.createElement(Typography, { mt: 12, variant: "body", color: "alt" },
                            "Practice will receive ",
                            1,
                            " credit(s)."))),
                React.createElement(RightContainer, null, isMobile ? (React.createElement(Typography, { mt: 16, variant: "body", color: "alt" },
                    "Practice will receive ",
                    1,
                    " credit(s).")) : (renderCard())))),
        React.createElement(CheckInProgress, { primaryButtonText: "Check In Another Patient", onContinue: function () {
                completeCheckIn();
                noPromptRestart();
            }, secondaryButtonText: "Return To Dashboard", onSecondaryContinue: function () {
                completeCheckIn();
                onComplete();
                navigate('/rewards/dashboard');
            } })));
};
var OuterContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  border: 1px solid ", ";\n\n  padding: 32px;\n  min-height: 25vh;\n  ", ";\n  @media screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  border: 1px solid ", ";\n\n  padding: 32px;\n  min-height: 25vh;\n  ", ";\n  @media screen and (max-width: ", "px) {\n    border: none;\n    padding: 0;\n    flex-direction: column;\n  }\n"])), function (props) { return props.theme.colors.border.base; }, customBreakpointStyles(1230, css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      flex-direction: column;\n      align-items: center;\n    "], ["\n      flex-direction: column;\n      align-items: center;\n    "])))), function (props) { return props.theme.breakpoints.md; });
var LeftContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: center;\n  margin-right: 40px;\n  ", ";\n  @media screen and (max-width: ", "px) {\n    margin-right: 0;\n    align-items: center;\n    & > :nth-child(2) {\n      align-self: flex-start;\n    }\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: center;\n  margin-right: 40px;\n  ", ";\n  @media screen and (max-width: ", "px) {\n    margin-right: 0;\n    align-items: center;\n    & > :nth-child(2) {\n      align-self: flex-start;\n    }\n  }\n"])), customBreakpointStyles(1230, css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      margin-right: 0;\n    "], ["\n      margin-right: 0;\n    "])))), function (props) { return props.theme.breakpoints.md; });
var RightContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  padding: 18px;\n  @media screen and (max-width: ", "px) {\n    flex-direction: column;\n    align-items: flex-start;\n    align-self: flex-start;\n    padding: unset;\n    & > img {\n      display: none;\n    }\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  padding: 18px;\n  @media screen and (max-width: ", "px) {\n    flex-direction: column;\n    align-items: flex-start;\n    align-self: flex-start;\n    padding: unset;\n    & > img {\n      display: none;\n    }\n  }\n"])), function (props) { return props.theme.breakpoints.md; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
