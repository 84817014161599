var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import View from './View';
export var ViewEnum;
(function (ViewEnum) {
    ViewEnum["TRANSACTION_DETAILS"] = "TRANSACTION_DETAILS";
    ViewEnum["DASHBOARD"] = "DASHBOARD";
})(ViewEnum || (ViewEnum = {}));
var RewardsHistoryContainer = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var _j = __read(useState(null), 2), injector = _j[0], setInjector = _j[1];
    var _k = __read(useState(null), 2), types = _k[0], setTypes = _k[1];
    var _l = __read(useState(0), 2), page = _l[0], setPage = _l[1];
    var _m = __read(useState(null), 2), dateRange = _m[0], setDateRange = _m[1];
    var _o = __read(useState(null), 2), selectedFacility = _o[0], setSelectedFacility = _o[1];
    var _p = __read(useState(ViewEnum.DASHBOARD), 2), currentView = _p[0], setCurrentView = _p[1];
    var _q = __read(useState(false), 2), showRewardsCounterDetails = _q[0], setShowRewardsCounterDetails = _q[1];
    var _r = __read(useState(false), 2), showFullHistory = _r[0], setShowFullHistory = _r[1];
    var _s = __read(useState([]), 2), selectedRewardTypes = _s[0], setSelectedRewardTypes = _s[1];
    var mockResponse = {
        "data": {
            "getTransactionHistoryActivity": {
                "pageCount": 1,
                "recordCount": 1,
                "reportId": "report1",
                "pageNumber": 1,
                "activity": [
                    {
                        "consumerFirstName": "John",
                        "consumerLastName": "Doe",
                        "activityType": "type1",
                        "occurredOn": "2022-01-01",
                        "phoneNumber": "1234567890",
                        "creditsEarned": 10,
                        "facilityName": "Facility 1",
                        "rewardAmount": 100,
                        "rewardEligibleAt": "2022-01-02",
                        "rewardExpiration": "2022-12-31",
                        "rewardPromotion": {
                            "accountId": "account1",
                            "amount": 100,
                            "endDate": "2022-12-31",
                            "terms": "terms1",
                            "campaignId": "campaign1",
                            "promoId": "promo1",
                            "couponColor": "red",
                            "startDate": "2022-01-01"
                        },
                        "rewardsGiven": 1,
                        "redeemedForQuantity": 1,
                        "redeemedForPoints": 10,
                        "orderId": "order1",
                        "staffFirstName": "Staff",
                        "staffLastName": "Member",
                        "email": "staff@company.com",
                        "checkInId": "checkin1",
                        "facilityId": "facility1",
                        "accountId": "account1",
                        "injector": {
                            "name": "Injector 1",
                            "uniqueId": "injector1",
                            "externalId": "external1"
                        }
                    }
                ]
            },
            "accountById": {
                "facilities": [
                    {
                        "name": "Facility 1",
                        "id": "facility1",
                        "shippingAddress": {
                            "city": "City 1",
                            "state": "State 1"
                        },
                        "status": "active"
                    }
                ]
            },
            "getInjectors": {
                "externalLookupSuccess": true,
                "injectors": [
                    {
                        "name": "Injector 1",
                        "uniqueId": "injector1",
                        "externalId": "external1"
                    }
                ]
            }
        }
    };
    return (React.createElement(View, { activities: (_b = (_a = mockResponse === null || mockResponse === void 0 ? void 0 : mockResponse.data) === null || _a === void 0 ? void 0 : _a.getTransactionHistoryActivity) === null || _b === void 0 ? void 0 : _b.activity, recordCount: (_d = (_c = mockResponse === null || mockResponse === void 0 ? void 0 : mockResponse.data) === null || _c === void 0 ? void 0 : _c.getTransactionHistoryActivity) === null || _d === void 0 ? void 0 : _d.recordCount, facilities: (_f = (_e = mockResponse === null || mockResponse === void 0 ? void 0 : mockResponse.data) === null || _e === void 0 ? void 0 : _e.accountById) === null || _f === void 0 ? void 0 : _f.facilities, injectors: (_h = (_g = mockResponse === null || mockResponse === void 0 ? void 0 : mockResponse.data) === null || _g === void 0 ? void 0 : _g.getInjectors) === null || _h === void 0 ? void 0 : _h.injectors, isLoading: false, error: null, onInjectorChange: setInjector, onTypesChange: setTypes, onPageChange: setPage, onDateRangeChange: setDateRange, onSelectedFacilityChange: setSelectedFacility, currentInjector: injector, currentTypes: types, currentDateRange: dateRange, selectedFacility: selectedFacility, onReset: function () { }, onEditDetails: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2];
        }); }); }, page: page, showRewardsCounterDetails: showRewardsCounterDetails, currentView: currentView, setCurrentView: setCurrentView, showFullHistory: showFullHistory, setShowFullHistory: setShowFullHistory, selectedRewardTypes: selectedRewardTypes, setSelectedRewardTypes: setSelectedRewardTypes }));
};
export default RewardsHistoryContainer;
